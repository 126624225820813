import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import { AppState } from '@state';

@Injectable({ providedIn: 'root' })
export class AppGuard {
    constructor(
        private store: Store,
        private navCtrl: NavController,
    ) {}

    canActivate() {
        const isNative = Capacitor.isNativePlatform();
        const { walkthroughCompleted, landingPageCompleted } = this.store.selectSnapshot(AppState);

        if (!isNative && !landingPageCompleted) {
            this.navCtrl.navigateRoot('landing');

            return false;
        }

        if (walkthroughCompleted) {
            return true;
        }

        this.navCtrl.navigateRoot('walkthrough');
    }
}
