import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';

import { Observable, throwError } from 'rxjs';

import { Client } from '@appbolaget/aware-model';

import { AuthState, RefreshClient, RefreshToken } from '@state';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { FormControl } from '@angular/forms';
import { catchError, takeWhile, tap } from 'rxjs/operators';
import { AwareHttpService } from '@appbolaget/aware-http';
import { ToastService } from '@services';

@Component({
    selector: 'app-app-lock-settings',
    templateUrl: 'app-lock-settings.component.html',
    styleUrls: ['app-lock-settings.component.scss'],
})
export class AppLockSettingsComponent implements OnInit, OnDestroy {
    @Select(AuthState.client) client$: Observable<Client>;

    codeLockControl = new FormControl(false);

    ALIVE = true;
    READY = false;

    constructor(
        private store: Store,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private api: AwareHttpService,
        private loadingCtrl: LoadingController,
        private toastService: ToastService,
    ) {}

    async ngOnInit() {
        await this.store.dispatch(new RefreshToken()).toPromise();

        const client = this.store.selectSnapshot(AuthState.client);

        if (this.getCodeLockAttrFromClient()?.json?.active) {
            this.codeLockControl.patchValue(true);
        }

        this.listen();

        this.READY = true;
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    close() {
        this.modalCtrl.dismiss();
    }

    async promptForNewCode(initialCode = '') {
        const alert = await this.alertCtrl.create({
            header: 'Välj kod',
            inputs: [
                {
                    type: 'password',
                    value: initialCode,
                    placeholder: 'Kod...',
                    name: 'code',
                    id: 'code-input',
                },
            ],
            buttons: [
                {
                    text: 'Avbryt',
                    handler: () => {
                        this.codeLockControl.patchValue(false, { emitEvent: false });
                    },
                },
                {
                    text: 'Spara',
                    handler: ({ code }) => {
                        if (code?.length < 4) {
                            this.toastService.info('Koden måste vara minst fyra tecken lång.');

                            return this.promptForNewCode(code);
                        }

                        this.saveCodeAttribute(code, true);
                    },
                },
            ],
        });

        alert.onWillDismiss().then(({ data }) => {
            if (!data) {
                this.codeLockControl.patchValue(false, { emitEvent: false });
            }
        });

        await alert.present();

        const codeInput = document.getElementById('code-input') as HTMLInputElement;

        codeInput.pattern = '[0-9]*';
        codeInput.inputMode = 'numeric';
    }

    private getCodeLockAttrFromClient(): any {
        const client = new Client(this.store.selectSnapshot(AuthState.client));
        const attr = client.attribute('codeLock');

        if (attr) {
            attr.json = JSON.parse(attr.json);
        }

        return attr;
    }

    private listen() {
        this.codeLockControl.valueChanges
            .pipe(
                takeWhile(() => this.ALIVE),
                tap((val: boolean) => {
                    if (val) {
                        this.promptForNewCode();
                    } else {
                        this.saveCodeAttribute(null, false);
                    }
                }),
            )
            .subscribe();
    }

    private async saveCodeAttribute(code: string, active: boolean) {
        const client = this.store.selectSnapshot(AuthState.client);
        const codeAttr = this.getCodeLockAttrFromClient();
        const json = {
            active,
            code,
        };

        if (active || codeAttr) {
            const loader = await this.loadingCtrl.create({ message: 'Sparar...' });

            loader.present();

            let request = this.api.post(`clients/${client.uuid}/attributes`, {
                key: 'codeLock',
                json,
            });

            if (codeAttr) {
                request = this.api.put(`clients/${client.uuid}/attributes/${codeAttr.uuid}`, {
                    json,
                });
            }

            request
                .execute()
                .pipe(
                    catchError((err) => {
                        loader.dismiss();

                        this.toastService.error('Något gick fel, försök igen.');

                        throw throwError(err);
                    }),
                    tap((res) => {
                        loader.dismiss();

                        if (code) {
                            this.store.dispatch(new RefreshToken());
                        }

                        this.toastService.success(
                            active ? 'Din kod har sparats.' : 'Din kod har avaktiverats.',
                        );
                    }),
                )
                .subscribe();
        }
    }
}
