import { Storage } from '@capacitor/storage';
import { AsyncStorageEngine } from '@appbolaget/ngxs-async-storage';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

export class NgxsAsyncStorageEngine implements AsyncStorageEngine {
    setItem(key: any, value: any): Observable<any> {
        return from(
            Storage.set({
                key,
                value,
            }),
        );
    }

    getItem(key: any): Observable<any> {
        return from(
            Storage.get({
                key,
            }),
        ).pipe(map((val) => val.value));
    }

    removeItem(key: any): Observable<any> {
        return from(
            Storage.remove({
                key,
            }),
        );
    }

    clear(): Observable<any> {
        return from(Storage.clear());
    }

    key(val: number): Observable<any> {
        return from(Storage.keys().then(({ keys }) => keys[val]));
    }

    length(): Observable<any> {
        return from(Storage.keys().then(({ keys }) => keys.length));
    }
}
