import { Injectable } from '@angular/core';
import { Unit } from '@appbolaget/aware-model';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
    constructor(private platform: Platform) {}

    setUnit(unit: Unit) {
        if (!Capacitor.isNative) {
            return;
        }

        FirebaseAnalytics.setUserProperty({
            name: 'unit',
            value: unit.title,
        });
    }

    setUserType(type: string) {
        if (!Capacitor.isNative) {
            return;
        }

        FirebaseAnalytics.setUserProperty({
            name: 'userType',
            value: type,
        });
    }

    logEvent(data: { name: string }) {
        if (!Capacitor.isNative) {
            return;
        }

        if (this.platform.is('capacitor')) {
            FirebaseAnalytics.logEvent({ ...data, params: {} });
        }
    }
}
