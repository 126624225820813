export class Login {
    static type = '[Auth] Attempt login';

    constructor(public credentials: LoginCredentials, public options?: LoginOptions) {}
}

export class LoginSuccessful {
    static type = '[Auth] Login successful';
}

export class LoginUnsuccessful {
    static type = '[Auth] Login unsuccessful';
}

export class Logout {
    static type = '[Auth] Logout';
}

export class RefreshToken {
    static type = '[Auth] Refresh token';
}

export class SetToken {
    static type = '[Auth] Set token';

    constructor(public token: string) {}
}

export class ResetIsCheckingToken {
    static type = '[Auth] Reset is checking token';
}

export class RefreshClient {
    static type = '[Auth] Refresh client';
}

interface LoginCredentials {
    username: string;
    password: string;
}

interface LoginOptions {
    rememberEmail?: boolean;
}
