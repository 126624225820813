import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FeedbackType } from '@helpers';
import { LoadingController, ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import * as moment from 'moment';

import { SaveFeedback } from '@state';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Feedback } from 'src/app/models';
import { ToastService } from '@services';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

@Component({
    selector: 'app-diary-editor',
    templateUrl: 'diary-editor.component.html',
    styleUrls: ['diary-editor.component.scss'],
})
export class DiaryEditorComponent implements OnInit, OnDestroy {
    feedback: Feedback;
    form: FormGroup;
    selectedEmotion = null;

    today = moment().format('YYYY-MM-DD');

    emotions = [
        {
            level: 1,
            text: 'Dåligt',
            selected: false,
        },
        {
            level: 2,
            text: 'Mindre bra',
            selected: false,
        },
        {
            level: 3,
            text: 'Okej',
            selected: false,
        },
        {
            level: 4,
            text: 'Bra',
            selected: false,
        },
        {
            level: 5,
            text: 'Jättebra',
            selected: false,
        },
    ];

    loader: any;

    ALIVE = true;

    constructor(
        private store: Store,
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private toastService: ToastService,
        private loadingCtrl: LoadingController,
    ) {}

    ngOnInit() {
        this.buildForm();

        if (this.feedback) {
            const emotion = this.emotions.find((e) => e.level === this.feedback.score);

            this.selectEmotion(emotion);
        }
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    selectEmotion(emotion): void {
        // Set newly selected emotion to be selected.
        emotion.selected = true;

        if (this.selectedEmotion) {
            // Set previous selected emotion to no longer be selected.
            this.selectedEmotion.selected = false;
        }

        // Set this.selectedEmotion to be equal the newly selected emotion.
        this.selectedEmotion = emotion;
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    async save(): Promise<void> {
        const { comment } = this.form.value;

        this.loader = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: 'Sparar...',
        });

        this.loader.present();

        this.store
            .dispatch(
                new SaveFeedback(
                    new Feedback({
                        comment,
                        score: this.selectedEmotion.level,
                        type: FeedbackType.Emotion,
                        uuid: this.feedback?.uuid || null,
                    }),
                ),
            )
            .pipe(
                catchError(async (err) => {
                    this.loader.dismiss();

                    this.toastService.error({
                        header: 'Ojdå!',
                        message: 'Något gick fel, vänligen försök igen!',
                    });

                    return throwError(err);
                }),
                tap(async () => {
                    this.loader.dismiss();

                    FirebaseAnalytics.logEvent({ name: 'save_diary', params: {} });

                    this.toastService.success('Ditt mående har sparats.');

                    this.close();
                }),
            )
            .subscribe();
    }

    private buildForm(): void {
        this.form = this.formBuilder.group({
            comment: [this.feedback?.comment || ''],
        });
    }
}
