import { Contact } from '../models';

export class SaveContact {
    static type = '[Contact] Save contact';

    constructor(public contact: Contact) {}
}

export class DeleteContact {
    static type = '[Contact] Delete contact';

    constructor(public contact: Contact) {}
}
