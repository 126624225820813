import { Component } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { catchError, filter, take, takeWhile, tap } from 'rxjs/operators';

import { AnalyticsService, ToastService, FeedbackService } from '@services';
import { Select, Store } from '@ngxs/store';
import { GetMorePageMenu, GetTabsMenu, SetAppReady, SetSplashLoaded } from './state/app.actions';
import { register } from 'swiper/element/bundle';
import {
    AppState,
    AuthState,
    GetDiary,
    GetSecurityPlanFields,
    GetSecurityPlanImage,
    NetworkState,
    RefreshToken,
    GetRegions,
    RefreshClient,
    Logout,
    GetOrganisation,
} from './state';
import { combineLatest, Observable, throwError } from 'rxjs';
import { environment } from '@env';
import { AwareHttpRequest, AwareHttpService } from '@appbolaget/aware-http';
import { AwareSecurityService } from '@appbolaget/aware-security';
import { Client } from '@appbolaget/aware-model';
import { CodeAuthComponent } from '@components';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Network } from '@capacitor/network';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    @Select(AppState.splashAnimationDone) splashAnimationDone$: Observable<boolean>;
    @Select(AppState.appReady) appReady$: Observable<boolean>;

    showSplash = Capacitor.isNativePlatform();

    userTypes: { title: string; uuid: string }[];

    constructor(
        private platform: Platform,
        private router: Router,
        private store: Store,
        private toastCtrl: ToastController,
        private alertCtrl: AlertController,
        private toastService: ToastService,
        private api: AwareHttpService,
        private loadingCtrl: LoadingController,
        private permissionService: AwareSecurityService,
        private analyticsService: AnalyticsService,
        private modalCtrl: ModalController,
        private feedbackService: FeedbackService,
    ) {
        this.handlePauseAndResume();
        this.initializeApp();
        this.loadComponents();

        if (!Capacitor.isNativePlatform()) {
            this.checkForCodeLock();
        }

        combineLatest([this.splashAnimationDone$, this.appReady$])
            .pipe(
                takeWhile(() => this.showSplash),
                tap((res) => {
                    const [splashAnimationDone, appReady] = res;

                    if (splashAnimationDone && appReady) {
                        this.showSplash = false;
                    }
                }),
            )
            .subscribe();
    }

    async initializeApp() {
        await this.platform.ready();

        this.listen();
        this.initOnRealDevice();

        this.platform.resume
            .pipe(
                tap(() => {
                    this.checkForCodeLock();
                }),
            )
            .subscribe();

        const { connected } = await Network.getStatus();

        if (connected) {
            await this.store.dispatch([new GetTabsMenu(), new GetMorePageMenu(), new SetAppReady(), new GetOrganisation()]).toPromise();

            if (this.store.selectSnapshot(AuthState.client)) {
                await this.store.dispatch([new GetSecurityPlanFields(false), new GetDiary(), new GetSecurityPlanImage()]).toPromise();
            }
        }

        this.store
            .select(AuthState.client)
            .pipe(
                filter((client) => !!client?.units),
                tap((client) => {
                    const { connected } = this.store.selectSnapshot(NetworkState);
                    const currentlyAttachedRegions = client.units?.filter((u) => Number(u.parent_id) === environment.api.unit_id);

                    if (currentlyAttachedRegions?.length) {
                        this.analyticsService.setUnit(currentlyAttachedRegions[0]);
                    }

                    if (!currentlyAttachedRegions?.length && connected) {
                        this.showRegionSelectAlert();
                    }

                    if (connected) {
                        this.checkIfForUserTypeMigration();
                    }
                }),
            )
            .subscribe();

        this.feedbackService.checkAppLaunchCount();
    }

    splashLoaded() {
        this.store.dispatch(new SetSplashLoaded());

        setTimeout(() => {
            this.showSplash = false;

            this.checkForCodeLock();
        }, 3000);
    }

    private checkIfForUserTypeMigration() {
        const organisation: any = this.store.selectSnapshot(AppState.organisation);
        const userTypes = organisation?.config?.app?.userTypes || null;
        const client: Client = this.store.selectSnapshot(AuthState.client);
        const userTypeAtr = client.attribute('userType');
        const typeUuid = userTypeAtr?.simple;

        if (typeUuid && userTypes) {
            const currentUserType = userTypes.find((t) => t.uuid === typeUuid);

            if (currentUserType.migrateTo) {
                const newUserType = userTypes.find((t) => t.uuid === currentUserType.migrateTo);

                this.api
                    .post(`clients/${client.uuid}/attributes/batch`, [
                        {
                            simple: newUserType.uuid,
                            key: 'userType',
                            uuid: userTypeAtr?.uuid || undefined,
                        },
                    ])
                    .execute()
                    .subscribe(() => {
                        this.store.dispatch(new RefreshClient());
                    });
            }
        }
    }

    private async checkForCodeLock() {
        if (this.store.selectSnapshot(AuthState.client)) {
            const codeAttr = this.getCodeLockAttrFromClient();
            console.log(codeAttr);

            if (codeAttr?.json?.active) {
                (
                    await this.modalCtrl.create({
                        component: CodeAuthComponent,
                        backdropDismiss: false,
                    })
                ).present();
            }
        }
    }

    private getCodeLockAttrFromClient(): any {
        const client = new Client(this.store.selectSnapshot(AuthState.client));
        const attr = client.attribute('codeLock');

        if (attr) {
            attr.json = JSON.parse(attr.json);
        }

        return attr;
    }

    private async showRegionSelectAlert() {
        await this.store.dispatch(new GetRegions()).toPromise();

        const { regions } = this.store.selectSnapshot(AppState);
        const { client } = this.store.selectSnapshot(AuthState);
        const isAdmin = await this.permissionService.isGranted('client.read', environment.api.unit).pipe(take(1)).toPromise();
        const inputs = regions.map((region) => {
            return {
                label: region.title,
                type: 'radio',
                value: region.uuid,
            };
        });

        (
            await this.alertCtrl.create({
                header: 'Välj region',
                backdropDismiss: false,
                inputs,
                buttons: [
                    {
                        text: 'Logga ut',
                        handler: () => this.store.dispatch(new Logout()),
                    },
                    {
                        text: 'Spara',
                        handler: async (uuid) => {
                            const loader = await this.loadingCtrl.create({
                                message: 'Laddar...',
                                spinner: 'crescent',
                            });

                            loader.present();

                            const requests: { [key: string]: AwareHttpRequest } = {};

                            requests.attach = this.api.put('suicidezero/attach', [{ id: uuid }]).module(null);

                            if (!isAdmin) {
                                requests.detach = this.api.put(`clients/${client.uuid}/detach/units`, [{ id: environment.api.unit }]);
                            }

                            this.api
                                .batchRequests(requests)
                                .execute()
                                .pipe(
                                    catchError((err) => {
                                        this.toastService.error({
                                            header: 'Ojdå!',
                                            message: 'Något gick fel, försök igen.',
                                        });

                                        loader.dismiss();

                                        this.showRegionSelectAlert();

                                        throw throwError(err);
                                    }),
                                    tap((res) => {
                                        loader.dismiss();

                                        this.toastService.success('Din region har sparats.');

                                        this.store.dispatch(new RefreshClient());
                                    }),
                                )
                                .subscribe();
                        },
                    },
                ],
            })
        ).present();
    }

    private loadComponents() {
        /**
         * "Hack" to load toast without internet access, since they are lazy-loaded.
         */
        this.toastCtrl.create({ animated: false }).then(async (toast) => {
            await toast.present();
            await toast.dismiss();
        });
    }

    private initOnRealDevice() {
        if (this.platform.is('capacitor')) {
            StatusBar.setStyle({
                style: Style.Light,
            });

            StatusBar.setBackgroundColor({
                color: '#f9f9fc',
            });
        }
    }

    private listen() {
        this.router.events
            .pipe(
                filter((e) => e instanceof NavigationEnd && this.platform.is('capacitor')),
                tap((e: NavigationEnd) => {
                    if (FirebaseAnalytics) {
                        FirebaseAnalytics.setScreenName({ screenName: e.url });
                    }
                }),
            )
            .subscribe();

        this.store
            .select(AuthState.client)
            .pipe(
                tap((client) => {
                    if (client) {
                        const { organisation } = this.store.selectSnapshot(AppState);
                        const userType = client.attr('userType');

                        if (organisation && userType) {
                            const typeObj = organisation.config?.app?.userTypes.find((t) => t.uuid === userType);

                            this.analyticsService.setUserType(typeObj.title);
                        } else {
                            this.analyticsService.setUserType(null);
                        }
                    } else {
                        this.analyticsService.setUserType(null);
                    }
                }),
            )
            .subscribe();
    }

    private handlePauseAndResume() {
        this.platform.pause
            .pipe(
                tap(() => {
                    // this.store.dispatch(new SetPausedAt(new Date()));
                }),
            )
            .subscribe();

        this.platform.resume
            .pipe(
                tap(() => {
                    // const { pausedAt } = this.store.selectSnapshot(AppState);

                    // this.store.dispatch(new SetPausedAt(null));

                    // if (pausedAt && isAfter(new Date(), addMinutes(pausedAt, 5))) {
                    const { client } = this.store.selectSnapshot(AuthState);

                    if (client) {
                        this.store
                            .dispatch(new RefreshToken())
                            .pipe(
                                tap(() => {
                                    this.store.dispatch([new GetTabsMenu(), new GetMorePageMenu()]);
                                }),
                            )
                            .subscribe();
                    } else {
                        this.store.dispatch([new GetTabsMenu(), new GetMorePageMenu()]);
                    }
                    // }
                }),
            )
            .subscribe();
    }
}
