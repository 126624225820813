import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import * as moment from 'moment';

import { SaveReminder } from '@state';
import { AnalyticsService, ToastService } from '@services';
import { Reminder } from 'src/app/models';
import { DeleteReminder } from '../../state/reminder.actions';
import { takeWhile, tap } from 'rxjs/operators';
import { LocalNotifications } from '@capacitor/local-notifications';

@Component({
    selector: 'app-reminder-editor',
    templateUrl: 'reminder-editor.component.html',
    styleUrls: ['reminder-editor.component.scss'],
})
export class ReminderEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('slides') slides: ElementRef | undefined;

    baseForm: FormGroup;
    noRepeatForm: FormGroup;
    repeatForm: FormGroup;
    remindersPausedForm: FormControl;

    reminder: Reminder;

    today = moment();
    max: moment.Moment;

    ALIVE = true;

    LOADED = false;

    constructor(
        private toastService: ToastService,
        private analyticsService: AnalyticsService,
        private store: Store,
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private alertCtrl: AlertController,
    ) {}

    ngOnInit() {
        if (!this.reminder) {
            this.reminder = new Reminder();
        }

        this.calculateAndSetMaxDate();

        this.buildForm();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.LOADED = true;
        }, 100);
    }

    onSwiperInit() {
        setTimeout(() => {
            const swiper = this.slides?.nativeElement.swiper;

            swiper.allowTouchMove = false;
        }, 150);
    }

    next() {
        this.slides?.nativeElement.swiper.slideNext();
    }

    previous() {
        this.slides?.nativeElement.swiper.slidePrev();
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    shouldSaveButtonBeDisabled(): boolean {
        const { repeat } = this.baseForm.value;
        const { date } = this.noRepeatForm.value;
        const { every, month, dayInMonth, day } = this.repeatForm.value;
        const time = repeat ? this.repeatForm.value.time : this.noRepeatForm.value.time;

        if (repeat) {
            if (every === 'day') {
                if (time?.length) {
                    return false;
                }
            } else if (every === 'week') {
                if (time?.length && day?.length) {
                    return false;
                }
            } else if (every === 'month') {
                if (time?.length && dayInMonth?.length) {
                    return false;
                }
            } else if (every === 'year') {
                if (time?.length && dayInMonth?.length && month?.length) {
                    return false;
                }
            }
        } else {
            if (date?.length && time?.length) {
                return false;
            }
        }

        return true;
    }

    async showDeletePrompt() {
        (
            await this.alertCtrl.create({
                header: 'Bekräfta',
                message: 'Vill du verkligen ta bort den här påminnelsen?',
                buttons: [
                    {
                        text: 'Ta bort',
                        handler: () => this.finalizeDelete(),
                    },
                    'Avbryt',
                ],
            })
        ).present();
    }

    async save(): Promise<void> {
        const { title, body, repeat } = this.baseForm.value;
        const { date } = this.noRepeatForm.value;
        const { every, month, dayInMonth, day } = this.repeatForm.value;
        const time = repeat ? this.repeatForm.value.time : this.noRepeatForm.value.time;

        this.analyticsService.logEvent({ name: 'save_reminder' });

        this.store.dispatch(
            new SaveReminder(
                new Reminder({
                    ...this.reminder,
                    repeat,
                    date,
                    time,
                    title,
                    body,
                    every,
                    month,
                    dayInMonth,
                    day,
                }),
            ),
        );

        this.close();
    }

    reminderPausedChanged() {
        const newPausedValue = !this.remindersPausedForm.value;

        this.reminder = new Reminder({ ...this.reminder, paused: newPausedValue });

        if (this.reminder.paused) {
            LocalNotifications.cancel({
                notifications: [{ id: this.reminder.id }],
            });
        }

        this.store.dispatch(new SaveReminder(new Reminder(this.reminder), !this.reminder.paused));
    }

    private async finalizeDelete() {
        this.store.dispatch(new DeleteReminder(this.reminder));

        this.close();

        this.toastService.success('Påminnelsen har tagits bort.');
    }

    private calculateAndSetMaxDate() {
        let addToMax = 12;

        const month = this.today.month() + 1;

        if (month < 12) {
            addToMax += 12 - month;
        }

        this.max = moment().add(addToMax, 'months');
    }

    private buildForm(): void {
        this.baseForm = this.formBuilder.group({
            repeat: [this.reminder?.repeat || false, Validators.required],
            title: [this.reminder?.title || '', Validators.required],
            body: [this.reminder?.body || ''],
        });

        this.noRepeatForm = this.formBuilder.group({
            date: [this.reminder?.date || null, Validators.required],
            time: [this.reminder?.time || null, Validators.required],
        });

        this.repeatForm = this.formBuilder.group({
            every: [this.reminder?.every || 'day', Validators.required],
            month: [this.reminder?.month || '', Validators.required],
            dayInMonth: [this.reminder?.dayInMonth || '', Validators.required],
            time: [this.reminder?.time || '', Validators.required],
            day: [this.reminder?.day || null, Validators.required],
        });

        if (this.reminder.id) {
            this.remindersPausedForm = new FormControl(!this.reminder.paused);

            this.remindersPausedForm.valueChanges
                .pipe(
                    takeWhile(() => this.ALIVE),
                    tap(() => this.reminderPausedChanged()),
                )
                .subscribe();
        }
    }
}
