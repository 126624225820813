import { AwareModel, Integer } from '@appbolaget/aware-model';
import * as moment from 'moment';

export class Feedback extends AwareModel {
    static fields = ['comment', 'score', 'type', 'created_at', 'uuid', 'id'];

    // @Integer()
    score: any;

    comment: string;
    type: string;
    created_at: string;
    uuid: string;
    id: number;
    day: number;

    constructor(data?: any) {
        super(data, Feedback);

        if (data?.created_at) {
            this.day = moment(data.created_at).day();
        }

        if (this.score) {
            this.score = parseInt(this.score, 10);
        }
    }
}
