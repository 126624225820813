import { Client, serializeAwareModel, Unit } from '@appbolaget/aware-model';
import { orderBy } from 'natural-orderby';

import { Contact, Reminder } from '../models';
import { Navigation } from '../models/navigation';

const models = {
    navigation: Navigation,
    client: Client,
};

export function customStateDeserializer(data) {
    if (typeof data !== 'string') {
        return data;
    }

    let parsed = JSON.parse(data);

    if (typeof parsed === 'object' && parsed.awareModelName) {
        parsed = new models[parsed.awareModelName](parsed);
    }

    if (parsed.client) {
        parsed.client = new Client(parsed.client);
    }

    if (parsed.organisation) {
        parsed.organisastion = new Unit(parsed.organisastion);
    }

    if (parsed.contacts) {
        parsed.contacts = parsed.contacts.map((contact) => new Contact(contact));
        parsed.contacts = orderBy(parsed.contacts, 'position', 'asc');
    }

    if (parsed.reminders) {
        parsed.reminders = parsed.reminders.map((reminder) => new Reminder(reminder));
    }

    return parsed;
}

export function customStateSerializer(data) {
    if (data instanceof Navigation) {
        data = serializeAwareModel(data, 'navigation');
    }

    if (data instanceof Client) {
        data = serializeAwareModel(data, 'client');
    }

    return JSON.stringify(data);
}
