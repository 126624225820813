export const template = `
<!DOCTYPE html>

<script>
  let dataAndLabels = {{dataAndLabels}};
  const chartScriptTag = document.createElement('script');
  chartScriptTag.src = 'https://cdn.jsdelivr.net/npm/chart.js@4.3.0/dist/chart.umd.min.js';
  document.head.appendChild(chartScriptTag);

  chartScriptTag.onload = () => {
    const container = document.getElementById('container');

    for (const dataAndLabel of dataAndLabels) {
        const index = dataAndLabels.indexOf(dataAndLabel);
        const { labels, data, year, monthText } = dataAndLabel;
        const monthContainer = document.createElement('div');

        monthContainer.style = 'display: flex; flex-direction: column; gap: 20px; align-items: center; height: 50vh; justify-content: center';

        if (index === 0) {
          const h1 = document.createElement('h1');
          h1.innerText = 'Mitt mående';
          h1.style = 'margin: 0; padding: 0; font-size: 48px; font-weight: 700';
          monthContainer.appendChild(h1);
        }

        const h2 = document.createElement('h2');

        h2.style = 'margin: 0; padding: 0; font-size: 34px; font-weight: 700';
        h2.innerText = \`\${monthText}, \${year}\`;

        const canvas = document.createElement('canvas');


        monthContainer.appendChild(h2);
        monthContainer.appendChild(canvas);
        container.appendChild(monthContainer);

    new Chart(canvas, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [
              {
                label: 'Mående',
                data: data,
                backgroundColor: '#64b97d'
              },
            ],
        },
        options: {
            animations: false,
            responsive: true,
            scales: {
              y: {
                  ticks: {
                    stepSize: 1,
                  },
                  max: 5,
                  min: 0,
                  beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
        },
      });
    }
  };
</script>

<html>
  <head>
    <title>Dagbok</title>
    <meta charset="utf-8" />
  </head>

  <body>
    <div id="container" style="display: flex; flex-direction: column">
  </body>
</html>


`;
