import { Injectable } from '@angular/core';

import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import { AppState } from '@state';

@Injectable({ providedIn: 'root' })
export class AppGuard  {
    constructor(private store: Store, private navCtrl: NavController) {}

    canActivate() {
        const { walkthroughCompleted } = this.store.selectSnapshot(AppState);

        if (walkthroughCompleted) {
            return true;
        }

        this.navCtrl.navigateRoot('walkthrough');
    }
}
