import { Pipe, PipeTransform } from '@angular/core';

const days = {
    0: 'söndag',
    1: 'måndag',
    2: 'tisdag',
    3: 'onsdag',
    4: 'torsdag',
    5: 'fredag',
    6: 'lördag',
};

@Pipe({ name: 'transformDay' })
export class DayPipe implements PipeTransform {
    transform(value: number): string {
        return days[value];
    }
}
