import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AppGuard } from './guards';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@pages/tabs/tabs.module').then((m) => m.TabsPageModule),
        canActivate: [AppGuard],
    },
    {
        path: 'walkthrough',
        loadChildren: () => import('@pages/walkthrough/walkthrough.module').then((m) => m.WalkthroughPageModule),
    },
    {
        path: 'security-plan',
        loadChildren: () => import('@pages/security-plan/security-plan.module').then((m) => m.SecurityPlanPageModule),
    },
    {
        path: 'post',
        loadChildren: () => import('@pages/post/post.module').then((m) => m.PostPageModule),
    },
    {
        path: 'contact-list',
        loadChildren: () => import('@pages/contact-list/contact-list.module').then((m) => m.ContactListPageModule),
    },
    {
        path: 'more-numbers',
        loadChildren: () => import('@pages/more-numbers/more-numbers.module').then((m) => m.MoreNumbersPageModule),
    },
    {
        path: 'landing',
        loadChildren: () => import('@pages/landing/landing.module').then((m) => m.LandingPageModule),
    },
];
@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
