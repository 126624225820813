import { Post as AwarePost } from '@appbolaget/aware-model';

export class Post extends AwarePost {
    base64Media: string[] = [];

    constructor(data?) {
        super(data, Post);

        if (data?.base64Media?.length) {
            this.base64Media = data.base64Media;
        }
    }
}
