import { Post } from '../models';

export class GetOrganisation {
    static type = '[App] Get organisation';
}

export class GetMorePageMenu {
    static type = '[App] Get more page menu';
}

export class GetMoreNumbersPageMenu {
    static type = '[App] Get more numbers page menu';
}

export class GetTabsMenu {
    static type = '[App] Get tabs menu';
}

export class SetSplashAnimationDone {
    static type = '[App] Set splash animation done';
}

export class SetAppReady {
    static type = '[App] Set app ready';
}

export class SetSplashLoaded {
    static type = '[App] Set splash loaded';
}

export class GetRegions {
    static type = '[App] Get regions';
}

export class SetWalkthroughCompleted {
    static type = '[App] Set walkthrough completed';

    constructor(public value: boolean = true) {}
}

export class SetLandingPageCompleted {
    static type = '[App] Set landing page completed';

    constructor(public value: boolean = true) {}
}

export class SetCurrentPost {
    static type = '[App] Set current post';

    constructor(public post: Post) {}
}

export class ResetLaunchCount {
    static type = '[App] Reset launch count';
}

export class NullLaunchCount {
    static type = '[App] Null launch count';
}

export class IncrementLaunchCount {
    static type = '[App] Increment launch count';
}
