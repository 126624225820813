import { Expand, Navigation as AwareNavigation } from '@appbolaget/aware-model';
import { environment } from '@env';
import { Post } from './post';

export class Navigation extends AwareNavigation {
    @Expand(Post, true)
    posts: Post[];

    @Expand(Post)
    post: Post;

    @Expand(Navigation, true)
    children: Navigation[];

    constructor(data) {
        super(data, Navigation);

        if (data?.posts?.length) {
            this.post = new Post(data.posts[0]);
            this.posts = data.posts.map((post) => new Post(post));
        }
    }
}
