import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { catchError, takeWhile, tap } from 'rxjs/operators';

import { AuthState, Login, LoginSuccessful, LoginUnsuccessful, Logout } from '@state';
import { AwareHttpService } from '@appbolaget/aware-http';
import { throwError } from 'rxjs';
import { ToastService } from '@services';
import { Client } from '@appbolaget/aware-model';

@Component({
    selector: 'app-code-auth',
    templateUrl: 'code-auth.component.html',
    styleUrls: ['code-auth.component.scss'],
})
export class CodeAuthComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('codeinput') codeinput: ElementRef;

    loginForm: FormGroup;
    codeControl = new FormControl('');

    loader: any;

    ALIVE = true;

    AUTH_BY_CODE = true;

    constructor(
        private alertCtrl: AlertController,
        private store: Store,
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private actions$: Actions,
        private loadingCtrl: LoadingController,
        private toastService: ToastService,
        private api: AwareHttpService,
        private navCtrl: NavController,
        private changeRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.listen();
        this.buildForms();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.codeinput.nativeElement.focus();
        }, 300);
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    logOut() {
        this.store.dispatch(new Logout());

        this.close();
    }

    toggleAuthByCode() {
        this.AUTH_BY_CODE = !this.AUTH_BY_CODE;
    }

    loginWithCode() {
        const codeAttr = this.getCodeLockAttrFromClient();

        if (codeAttr.json.code === this.codeControl.value) {
            this.close();
        } else {
            this.toastService.error('Den angivna koden är felaktig.');
        }
    }

    async login(): Promise<void> {
        const { password } = this.loginForm.value;

        this.loader = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: 'Loggar in...',
        });

        this.loader.present();

        this.store.dispatch(new Login({ username: this.loginForm.controls.email.value, password }));
    }

    async showForgotPasswordPrompt() {
        (
            await this.alertCtrl.create({
                header: 'Återställ lösenord',
                message:
                    'Fyll i din e-postadress så skickar vi ett mejl med information för att återställa ditt lösenord.',
                inputs: [
                    {
                        placeholder: 'E-postadress...',
                        name: 'email',
                        type: 'email',
                    },
                ],
                buttons: [
                    'Avbryt',
                    {
                        text: 'Skicka',
                        handler: ({ email }) => this.resetPassword(email),
                    },
                ],
            })
        ).present();
    }

    private async resetPassword(email: string) {
        const loader = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: 'Skickar mejl...',
        });

        loader.present();

        this.api
            .post('authenticate/password/restore', { email })
            .header('source', 'universal.appbolaget.se')
            .execute()
            .pipe(
                catchError(async (err) => {
                    loader.dismiss();

                    this.toastService.error({
                        header: 'Ojdå!',
                        message: 'Något gick fel, vänligen försök igen.',
                    });

                    return throwError(err);
                }),
                tap(async (res) => {
                    loader.dismiss();

                    this.toastService.success(
                        'Vi har skickat ett mejl med instruktioner till dig.',
                    );
                }),
            )
            .subscribe();
    }

    private buildForms(): void {
        const client = this.store.selectSnapshot(AuthState.client);

        this.loginForm = this.formBuilder.group({
            email: { value: client.email, disabled: true },
            password: ['', Validators.required],
        });

        this.loginForm.valueChanges
            .pipe(
                takeWhile(() => this.ALIVE),
                tap(() => {
                    setTimeout(() => {
                        this.loginForm.updateValueAndValidity();
                    }, 100);
                }),
            )
            .subscribe();
    }

    private listen(): void {
        this.actions$
            .pipe(
                ofActionDispatched(LoginSuccessful),
                takeWhile(() => this.ALIVE),
                tap(async () => {
                    this.close();

                    this.loader.dismiss();

                    this.close();
                }),
            )
            .subscribe();

        this.actions$
            .pipe(
                ofActionDispatched(LoginUnsuccessful),
                takeWhile(() => this.ALIVE),
                tap(async () => {
                    this.loader.dismiss();

                    this.toastService.error({
                        header: 'Ojdå!',
                        message: 'Något gick fel, vänligen försök igen.',
                    });
                }),
            )
            .subscribe();
    }

    private getCodeLockAttrFromClient(): any {
        const client = new Client(this.store.selectSnapshot(AuthState.client));
        const attr = client.attribute('codeLock');

        if (attr) {
            attr.json = JSON.parse(attr.json);
        }

        return attr;
    }
}
