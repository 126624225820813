import { Pipe, PipeTransform } from '@angular/core';

const months = {
    0: 'januari',
    1: 'februari',
    2: 'mars',
    3: 'april',
    4: 'maj',
    5: 'juni',
    6: 'juli',
    7: 'augusti',
    8: 'september',
    9: 'oktober',
    10: 'november',
    11: 'december',
};

@Pipe({ name: 'transformMonth' })
export class MonthPipe implements PipeTransform {
    transform(value: number): string {
        return months[value];
    }
}
