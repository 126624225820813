import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import {
    AppLockSettingsComponent,
    AuthComponent,
    CodeAuthComponent,
    ContactEditorComponent,
    ContactsComponent,
    DiaryEditorComponent,
    DiaryStatsComponent,
    FeedbackComponent,
    RegisterComponent,
    ReminderEditorComponent,
} from '@components';
import { DayPipe, EmotionPipe, IconPipe, MonthPipe } from './pipes';
import { ToggleNavigationDirective } from './directives/toggle-navigation.directive';
import { MomentModule } from 'ngx-moment';

const entryComponents = [
    AppLockSettingsComponent,
    AuthComponent,
    CodeAuthComponent,
    DiaryEditorComponent,
    DiaryStatsComponent,
    FeedbackComponent,
    ContactEditorComponent,
    ContactsComponent,
    ReminderEditorComponent,
    RegisterComponent,
];
const components = [...entryComponents];
const pipes = [DayPipe, MonthPipe, IconPipe, EmotionPipe];
const directives = [ToggleNavigationDirective];

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        MomentModule,
    ],
    exports: [
        MomentModule,
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        ...components,
        ...pipes,
        ...directives,
    ],
    declarations: [...components, ...pipes, ...directives],
    providers: [...pipes],
})
export class SharedModule {}
