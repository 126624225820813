import { Pipe, PipeTransform } from '@angular/core';

const emotions = {
    1: '😞',
    2: '🙁',
    3: '😐',
    4: '🙂',
    5: '😀',
};

@Pipe({ name: 'emotion' })
export class EmotionPipe implements PipeTransform {
    transform(value: number): string {
        return emotions[value];
    }
}
