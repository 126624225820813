import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AwareSecurityService } from '@appbolaget/aware-security';
import { Store } from '@ngxs/store';

import { AuthState, AppState } from '@state';
import { Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { environment } from '@env';
import { Navigation } from '../models/navigation';

@Directive({
    selector: '[toggleNavigation]',
})
export class ToggleNavigationDirective implements OnDestroy {
    IS_IN_VIEW = false;
    destroy$ = new Subject<void>();

    @Input() set toggleNavigation(navigation: Navigation) {
        this.store
            .select(AuthState.client)
            .pipe(
                takeUntil(this.destroy$),
                tap((client) => {
                    const loginRequired = Boolean(navigation.attribute('login_required')?.simple);
                    const requiredPermissions = navigation.attr('login_required', 'json');
                    const hasPermission = this.securityService.isGranted(
                        requiredPermissions,
                        environment.api.unit,
                    );

                    if (loginRequired && !client && hasPermission) {
                        this.viewContainerRef.clear();
                        this.IS_IN_VIEW = false;
                    } else if (!this.IS_IN_VIEW) {
                        this.viewContainerRef.createEmbeddedView(this.templateRef);
                        this.IS_IN_VIEW = true;
                    }
                }),
            )
            .subscribe();
    }

    constructor(
        private store: Store,
        private securityService: AwareSecurityService,
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
    ) {}

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
