import { State, StateContext, Action, Selector, NgxsOnInit, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AwareHttpRequest, AwareHttpRequestMethod, AwareHttpService } from '@appbolaget/aware-http';

import { StateKey } from '@helpers';
import { GetMorePageMenu, GetTabsMenu, GetOrganisation } from './app.actions';
import {
    ExecuteCachedRequests,
    SaveRequestToCache,
    SetConnected,
    SetInitialNetworkStatusTrue,
} from './network.actions';
import { LoadingController } from '@ionic/angular';
import { RefreshToken } from './auth.actions';
import { CachedRequest } from '../models';
import { delay, tap } from 'rxjs/operators';
import { AuthState } from './auth.state';
import { GetSecurityPlanFields, GetSecurityPlanImage } from './security-plan.actions';
import { GetDiary } from './diary.actions';
import { from } from 'rxjs';
import { ToastService } from '@services/toast.service';
import { Network } from '@capacitor/network';

export interface NetworkStateModel {
    connected: boolean;
    initialNetworkStatusSet: boolean;
    cachedRequests: CachedRequest[];
}

const defaults = {
    connected: false,
    initialNetworkStatusSet: false,
    cachedRequests: [],
};

@State<NetworkStateModel>({
    name: StateKey.Network,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class NetworkState implements NgxsOnInit {
    @Selector()
    static connected({ connected }: NetworkStateModel): boolean {
        return connected;
    }

    @Selector()
    static initialNetworkStatusSet({ initialNetworkStatusSet }: NetworkStateModel): boolean {
        return initialNetworkStatusSet;
    }

    constructor(
        private api: AwareHttpService,
        private store: Store,
        private toastService: ToastService,
        private loadingCtrl: LoadingController,
    ) {}

    ngxsOnInit({ dispatch, patchState, getState }: StateContext<NetworkStateModel>): void {
        this.listenToNetworkStatus(getState, patchState);
    }

    @Action(SetConnected)
    getMorePageMenu({ patchState }: StateContext<NetworkStateModel>, { connected }: SetConnected) {
        patchState({ connected });
    }

    @Action(SetInitialNetworkStatusTrue)
    setInitialNetworkStatusTrue({ patchState }: StateContext<NetworkStateModel>) {
        patchState({ initialNetworkStatusSet: true });
    }

    @Action(SaveRequestToCache)
    saveRequestToCache(
        { patchState, getState }: StateContext<NetworkStateModel>,
        { request }: SaveRequestToCache,
    ) {
        const cachedRequest = new CachedRequest(request);
        const { cachedRequests } = getState();

        patchState({
            cachedRequests: [...cachedRequests, cachedRequest],
        });
    }

    @Action(ExecuteCachedRequests)
    executeCachedRequests({ patchState, getState }: StateContext<NetworkStateModel>) {
        const promise = new Promise<void>(async (resolve) => {
            const { cachedRequests } = getState();

            const loader = await this.loadingCtrl.create({
                message: 'Synkroniserar data...',
                backdropDismiss: false,
                spinner: 'crescent',
            });

            loader.present();

            for (const cachedRequest of cachedRequests) {
                const request = this.rebuildRequest(cachedRequest);
                const { token } = this.store.selectSnapshot(AuthState);

                if (token) {
                    request.header('token', token);
                }

                await from(request.execute().toPromise()).pipe(delay(50)).toPromise();
            }

            loader.dismiss();

            patchState({ cachedRequests: [] });

            resolve();
        });

        return from(promise);
    }

    private rebuildRequest(req): AwareHttpRequest {
        const { method, url, body, headers, parameters, subRequests } = req;
        const request = new AwareHttpRequest(this.api)
            .method(method as AwareHttpRequestMethod)
            .url(url)
            .headers(headers);

        if (body) {
            request.body(body);
        }

        if (parameters) {
            request.parameters(parameters);
        }

        if (subRequests?.length) {
            for (const subReq of subRequests) {
                request.addSubRequest(this.rebuildRequest(subReq));
            }
        }

        return request;
    }

    private async listenToNetworkStatus(getState, patchState) {
        const currentNetworkStatus = await Network.getStatus();

        this.store.dispatch([
            new SetConnected(currentNetworkStatus.connected),
            new SetInitialNetworkStatusTrue(),
        ]);

        Network.addListener('networkStatusChange', async (status) => {
            const newStatus = status.connected;
            const previousStatus = getState().connected;

            if (newStatus && !previousStatus) {
                this.store.dispatch(new SetConnected(newStatus));

                await this.store.dispatch(new RefreshToken()).toPromise();

                this.store
                    .dispatch(new ExecuteCachedRequests())
                    .pipe(
                        tap(() => {
                            this.store.dispatch([
                                new GetTabsMenu(),
                                new GetMorePageMenu(),
                                new GetOrganisation(),
                            ]);

                            if (this.store.selectSnapshot(AuthState.client)) {
                                this.store.dispatch([
                                    new GetSecurityPlanFields(false),
                                    new GetDiary(),
                                    new GetSecurityPlanImage(),
                                ]);
                            }
                        }),
                    )
                    .subscribe();

                this.toastService.info({
                    header: 'Nätverksinformation',
                    message: 'Du har återfått din internetuppkoppling.',
                    duration: 30000,
                    buttons: ['Okej'],
                });
            }

            if (!newStatus && previousStatus) {
                this.store.dispatch(new SetConnected(newStatus));

                this.toastService.info({
                    header: 'Nätverksinformation',
                    message:
                        'Du har tappat din internetuppkoppling, vissa funktioner i appen fungerar inte utan internetuppkoppling.',
                    duration: 30000,
                    buttons: ['Okej'],
                });
            }
        });
    }
}
