export class GetSecurityPlanFields {
    static type = '[Security Plan] Get security plan fields';

    constructor(public showLoader: boolean = true) {}
}

export class StoreSecurityPlanLocally {
    static type = '[Security Plan] Store security plan locally';

    constructor(public json: any) {}
}

export class GetSecurityPlanImage {
    static type = '[Security Plan] Get security plan image';
}

export class StoreSecurityPlanImageLocally {
    static type = '[Security Plan] Store security plan image locally';

    constructor(public base64: string) {}
}
