import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AppState } from '@state/app.state';
import { AuthState } from '@state/auth.state';
import { AlertController } from '@ionic/angular';
import { Client, Attribute } from '@appbolaget/aware-model';
import { AwareHttpService } from '@appbolaget/aware-http';
import { Browser } from '@capacitor/browser';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
    constructor(
        private store: Store,
        private alertCtrl: AlertController,
        private api: AwareHttpService,
    ) {}

    checkAppLaunchCount() {
        const client = this.store.selectSnapshot(AuthState.client);
        const organisation = this.store.selectSnapshot(AppState.organisation);
        const config = organisation.config as any;
        const userTypes = config.app.userTypes;

        if (!client) return;

        const typeUuid = client.attribute('userType').simple;

        const appLaunchCount = this.incrementLaunchCountAndReturnNewLaunchCount();
        const hasRepliedToFeedbackAttr = client.attribute('hasRepliedToFeedback');

        if (hasRepliedToFeedbackAttr?.simple === '1') {
            return;
        }

        if (userTypes.find((t) => t.uuid === typeUuid).showFeedbackPopup) {
            if (appLaunchCount === 10) {
                this.showAppRatingAlert();
            }

            if (appLaunchCount === 20) {
                this.showAppRatingAlert();
            }
        }
    }

    private async showAppRatingAlert() {
        const client = this.store.selectSnapshot(AuthState.client);
        const organisation = this.store.selectSnapshot(AppState.organisation);
        const config = organisation.config as any;
        const userTypes = config.app.userTypes;
        const typeUuid = client.attribute('userType').simple;
        const hasRepliedToFeedbackAttr = client.attribute('hasRepliedToFeedback');

        const alert = await this.alertCtrl.create({
            header: userTypes.find((t) => t.uuid === typeUuid).feedbackHeader,
            message: '',
            buttons: [
                {
                    text: 'INTE NU',
                    cssClass: 'alert-button-cancel',
                },
                {
                    text: 'SVARA PÅ ENKÄT',
                    cssClass: 'alert-button-confirm',
                    handler: async () => {
                        Browser.open({
                            url: userTypes.find((t) => t.uuid === typeUuid).feedbackUrl,
                        });

                        if (!hasRepliedToFeedbackAttr) {
                            this.createHasRepliedToFeedbackAttribute(client);
                        } else {
                            this.setHasRepliedToFeedbackAttributeToTrue(
                                client,
                                hasRepliedToFeedbackAttr,
                            );
                        }
                    },
                },
            ],
            backdropDismiss: false,
        });

        await alert.present();

        document.querySelector('.alert-message').innerHTML = userTypes.find(
            (t) => t.uuid === typeUuid,
        ).feedbackDescription;
    }

    private createHasRepliedToFeedbackAttribute(client: Client) {
        this.api
            .post(`clients/${client.uuid}/attributes`, {
                key: 'hasRepliedToFeedback',
                simple: '1',
            })
            .execute()
            .subscribe();
    }

    private setHasRepliedToFeedbackAttributeToTrue(client: Client, attr: Attribute) {
        this.api
            .put(`clients/${client.uuid}/attributes/${attr.uuid}`, {
                simple: '1',
            })
            .execute()
            .subscribe();
    }

    private incrementLaunchCountAndReturnNewLaunchCount() {
        const client = this.store.selectSnapshot(AuthState.client);
        const appLaunchCountAttr = client.attribute('appLaunchCount');

        if (!appLaunchCountAttr) {
            this.createAppLaunchCountAttr(client);

            return 1;
        }

        this.incrementLaunchCountAttr(client, appLaunchCountAttr);

        return parseInt(appLaunchCountAttr.simple, 10) + 1;
    }

    private incrementLaunchCountAttr(client: Client, attr: Attribute) {
        this.api
            .put(`clients/${client.uuid}/attributes/${attr.uuid}`, {
                simple: String(parseInt(attr.simple, 10) + 1),
            })
            .execute()
            .subscribe();
    }

    private createAppLaunchCountAttr(client: Client) {
        this.api
            .post(`clients/${client.uuid}/attributes`, {
                key: 'appLaunchCount',
                simple: '1',
            })
            .execute()
            .subscribe();
    }
}
