import { State, StateContext, Action, Selector, NgxsOnInit, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { AwareHttpService } from '@appbolaget/aware-http';

import { StateKey } from '@helpers';
import {
    GetSecurityPlanFields,
    GetSecurityPlanImage,
    StoreSecurityPlanImageLocally,
    StoreSecurityPlanLocally,
} from './security-plan.actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Attribute, Client, FieldCategory } from '@appbolaget/aware-model';
import { AuthState } from './auth.state';
import { LoadingController, NavController } from '@ionic/angular';
import { environment } from '@env';
import { throwError } from 'rxjs';
import { ToastService } from '@services/toast.service';

export interface SecurityPlanStateModel {
    fields: FieldCategory[];
    securityPlan: any;
    isSynced: boolean;
    image: string;
    imageUuid: string;
}

const defaults = {
    fields: null,
    securityPlan: null,
    isSynced: false,
    image: null,
    imageUuid: null,
};

@State<SecurityPlanStateModel>({
    name: StateKey.SecurityPlan,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class SecurityPlanState implements NgxsOnInit {
    @Selector()
    static fields({ fields }: SecurityPlanStateModel): FieldCategory[] {
        return fields;
    }

    @Selector()
    static securityPlan({ securityPlan }: SecurityPlanStateModel): any {
        return securityPlan;
    }

    @Selector()
    static image({ image }: SecurityPlanStateModel): string {
        return image;
    }

    @Selector()
    static imageUuid({ imageUuid }: SecurityPlanStateModel): string {
        return imageUuid;
    }

    constructor(
        private api: AwareHttpService,
        private store: Store,
        private loadingCtrl: LoadingController,
        private navCtrl: NavController,
        private toastService: ToastService,
    ) {}

    ngxsOnInit({ dispatch, patchState }: StateContext<SecurityPlanStateModel>): void {}

    @Action(StoreSecurityPlanLocally)
    storeSecurityPlanLocally(
        { patchState, getState }: StateContext<SecurityPlanStateModel>,
        { json }: StoreSecurityPlanLocally,
    ) {
        const { securityPlan } = getState();

        patchState({ securityPlan: { ...securityPlan, json } });
    }

    @Action(StoreSecurityPlanImageLocally)
    storeSecurityPlanImageLocally(
        { patchState, getState }: StateContext<SecurityPlanStateModel>,
        { base64 }: StoreSecurityPlanImageLocally,
    ) {
        patchState({ image: base64 });
    }

    @Action(GetSecurityPlanImage)
    getSecurityPlanImage({ patchState, dispatch }: StateContext<SecurityPlanStateModel>) {
        const { client } = this.store.selectSnapshot(AuthState);

        return this.api
            .get(`clients/${client.uuid}`)
            .expand('meta')
            .toModel(Client)
            .execute()
            .pipe(
                tap(async (client: Client) => {
                    const imageUuid = client.getMeta('securityPlanImage');

                    if (imageUuid) {
                        patchState({ imageUuid });

                        const data = await fetch(
                            `${environment.api.url}/@media/${imageUuid}?w=300`,
                        );
                        const blob = await data.blob();

                        new Promise<string>(async (resolve) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);

                            reader.onloadend = () => {
                                const base64data = reader.result;

                                resolve(base64data as string);
                            };
                        }).then((base64) => {
                            dispatch(new StoreSecurityPlanImageLocally(base64));
                        });
                    } else {
                        dispatch(new StoreSecurityPlanImageLocally(null));
                    }
                }),
            );
    }

    @Action(GetSecurityPlanFields)
    async getSecurityPlanFields(
        { patchState }: StateContext<SecurityPlanStateModel>,
        { showLoader }: GetSecurityPlanFields,
    ) {
        const { client } = this.store.selectSnapshot(AuthState);

        let loading = null;

        if (showLoader) {
            loading = await this.loadingCtrl.create({
                backdropDismiss: false,
                message: 'Laddar...',
                spinner: 'crescent',
            });

            loading.present();
        }

        this.api
            .get(`clients/${client.uuid}/attributes`)
            .toCollection(Attribute)
            .execute()
            .pipe(
                tap(({ data }) => {
                    const isSynced = data.find(
                        (a) => a.alias === `${client.uuid}:securityPlanSynced`,
                    );
                    const securityPlan = data.find(
                        (a) => a.alias === `${client.uuid}:securityPlan`,
                    );

                    try {
                        securityPlan.json = JSON.parse(securityPlan.json);
                    } catch (error) {}

                    patchState({
                        isSynced: isSynced ? true : false,
                        securityPlan: securityPlan ? securityPlan : null,
                    });

                    if (isSynced) {
                        if (loading) {
                            loading.dismiss();
                        }
                    } else {
                        this.syncOldFields(loading, securityPlan);
                    }
                }),
            )
            .subscribe();
    }

    private async syncOldFields(loader, securityPlan: Attribute) {
        const oldFields = await this.getOldFields().toPromise();
        const newFields = {
            q1SignOne: '',
            q1SignTwo: '',
            q1SignThree: '',
            q1SignFour: '',

            q2One: '',
            q2Two: '',
            q2Three: '',

            q3NameOne: '',
            q3PhoneOne: '',
            q3NameTwo: '',
            q3PhoneTwo: '',
            q3LocationOne: '',
            q3LocationTwo: '',

            q5NameOne: '',
            q5PhoneOne: '',
            q5NameTwo: '',
            q5PhoneTwo: '',
            q5EmergencyNumber: '',
            q5EmergencyAdress: '',

            q6One: '',
            q6Two: '',

            q7One: '',
        };

        for (const key in newFields) {
            for (const f of oldFields) {
                const found = f.fields.find(
                    (field) => field.alias === `${environment.api.unit}:${key}`,
                );

                if (found) {
                    if (found.answers?.length) {
                        newFields[key] = found.answers[0].value_extended;
                    }

                    break;
                }
            }
        }

        const { client } = this.store.selectSnapshot(AuthState);

        const requests: any = {};

        requests.sync = this.api.post(`clients/${client.uuid}/attributes`, {
            alias: `${client.uuid}:securityPlanSynced`,
            key: 'securityPlan',
            simple: true,
        });

        requests.plan = this.api.post(`clients/${client.uuid}/attributes`, {
            alias: `${client.uuid}:securityPlan`,
            key: 'securityPlan',
            json: JSON.stringify(newFields),
        });

        this.api
            .batchRequests(requests)
            .execute()
            .pipe(
                catchError(async (err) => {
                    if (loader) {
                        loader.dismiss();
                    }

                    this.navCtrl.navigateRoot('tabs/home');

                    this.toastService.error('Något gick fel, försök igen.');

                    return throwError(err);
                }),
                tap(() => {
                    setTimeout(() => {
                        this.store.dispatch(new GetSecurityPlanFields(loader ? true : false));

                        if (loader) {
                            loader.dismiss();
                        }
                    }, 300);
                }),
            )
            .subscribe();
    }

    private getOldFields() {
        return this.api
            .get('categories')
            .type('field_nav')
            .expand(['children.fields'])
            .filter({
                parent_id: 'is null',
            })
            .toCollection(FieldCategory)
            .execute()
            .pipe(
                switchMap(({ data }) => {
                    const requests = {};

                    for (const child of data[0].children) {
                        const request = this.api
                            .get('fields/hierarchy')
                            .toCollection(FieldCategory)
                            .parameter('category_id', child.uuid);

                        requests[Object.keys(requests).length + 1] = request;
                    }

                    return this.api
                        .batchRequests(requests)
                        .execute()
                        .pipe(
                            map((res) => {
                                const fields = [];

                                for (const key in res.data) {
                                    if (res.data[key]) {
                                        fields.push(res.data[key].data[0]);
                                    }
                                }

                                return fields;
                            }),
                        );
                }),
            );
    }
}
