import { State, StateContext, Action, Selector, Store, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError, delay } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';

import { AwareHttpService } from '@appbolaget/aware-http';

import { Client } from '@appbolaget/aware-model';
import {
    Login,
    LoginUnsuccessful,
    LoginSuccessful,
    Logout,
    RefreshToken,
    SetToken,
    ResetIsCheckingToken,
    RefreshClient,
} from './auth.actions';
import { ResetLaunchCount } from './app.actions';
import { StateKey } from '@helpers';
import { Network } from '@capacitor/network';

const jwtHelper = new JwtHelperService();

export interface AuthStateModel {
    client: Client;
    token: string;
    refreshToken: string;
    isCheckingToken: boolean;
    lastSuccessfulEmail: string;
}

const defaults: AuthStateModel = {
    client: null,
    token: null,
    refreshToken: null,
    isCheckingToken: false,
    lastSuccessfulEmail: null,
};

@State<AuthStateModel>({
    name: StateKey.Auth,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class AuthState implements NgxsOnInit {
    constructor(private api: AwareHttpService, private store: Store) {}

    @Selector()
    static client({ client }: AuthStateModel) {
        return client;
    }

    @Selector()
    static token({ token }: AuthStateModel) {
        return token;
    }

    @Selector()
    static lastSuccessfulEmail({ lastSuccessfulEmail }: AuthStateModel) {
        return lastSuccessfulEmail;
    }

    @Selector()
    static refreshToken({ refreshToken }: AuthStateModel) {
        return refreshToken;
    }

    ngxsOnInit({ getState, patchState }: StateContext<AuthStateModel>) {
        const { client, token, refreshToken } = getState();

        if (!client && (token || refreshToken)) {
            patchState({
                token: null,
                refreshToken: null,
            });
        }

        this.store
            .select(AuthState.token)
            .pipe(tap((token) => this.api.setToken(token)))
            .subscribe();
    }

    @Action(Login)
    login({ patchState, dispatch }: StateContext<AuthStateModel>, { credentials, options }: Login) {
        return this.api
            .post('authenticate', {
                ...credentials,
                type: 'email',
                no_ip_validation: true,
                remember: 5256000,
            })
            .expand(['units', 'communications', 'attributes'])
            .execute()
            .pipe(
                catchError((err) => {
                    dispatch(new LoginUnsuccessful());

                    return throwError(err);
                }),
                tap((result) => {
                    dispatch([new LoginSuccessful(), new SetToken(result.data.token)]);

                    patchState({
                        client: new Client(result.data.client),
                        refreshToken: result.data.refresh_token,
                        lastSuccessfulEmail: options?.rememberEmail ? credentials.username : null,
                    });
                }),
            );
    }

    @Action(Logout)
    logout({ patchState, dispatch, getState }: StateContext<AuthStateModel>) {
        patchState({
            ...defaults,
            lastSuccessfulEmail: getState().lastSuccessfulEmail,
        });

        dispatch([new SetToken(null), new ResetLaunchCount()]);
    }

    @Action(ResetIsCheckingToken)
    resetIsCheckingToken({ patchState }: StateContext<AuthStateModel>) {
        patchState({ isCheckingToken: false });
    }

    @Action(RefreshToken)
    refreshToken({ dispatch, getState, patchState }: StateContext<AuthStateModel>) {
        const { refreshToken, isCheckingToken } = getState();

        return this.api
            .post('authenticate/refresh', {
                refresh_token: refreshToken,
                no_ip_validation: true,
            })
            .token(null)
            .execute()
            .pipe(
                delay(100),
                tap((result) => {
                    const { token, client } = result.data;

                    dispatch(new SetToken(token));

                    patchState({ client: new Client(client) });

                    if (!isCheckingToken) {
                        this.checkToken();

                        patchState({ isCheckingToken: true });
                    }
                }),
                catchError(async (error) => {
                    const { connected } = await Network.getStatus();

                    if (connected && error?.data?.type !== 'auth_cooldown') {
                        dispatch(new Logout());

                        patchState({ token: null, refreshToken: null });
                    }

                    if (error?.data?.type === 'auth_cooldown') {
                        setTimeout(() => {
                            dispatch(new RefreshToken());
                        }, 5000);
                    }

                    return throwError(error);
                }),
            );
    }

    @Action(SetToken)
    setToken({ patchState }: StateContext<AuthStateModel>, { token }: SetToken) {
        this.api.setToken(token);

        patchState({ token });
    }

    @Action(RefreshClient)
    refreshClient({ patchState, getState }: StateContext<AuthStateModel>) {
        return this.api
            .get(`clients/me`)
            .expand(['units', 'communications', 'attributes', 'roles', 'meta'])
            .toModel(Client)
            .execute()
            .pipe(tap((c) => patchState({ client: c })));
    }

    private checkToken(): void {
        const { token } = this.store.selectSnapshot(AuthState);

        if (token) {
            const expDate = jwtHelper.getTokenExpirationDate(token);
            const expDateMinusThreeMinutes = moment(expDate).subtract(3, 'minutes');

            if (moment().isSameOrAfter(expDateMinusThreeMinutes)) {
                this.store.dispatch(new RefreshToken());
            }
        }

        setTimeout(() => this.checkToken(), 15000);
    }
}
