import { HttpParams } from '@angular/common/http';
import { AwareHttpRequest } from '@appbolaget/aware-http';

export class CachedRequest {
    method: string;
    url: string;
    body: any;
    headers: { [key: string]: string } = {};
    parameters: { [key: string]: string } = {};
    subRequests: CachedRequest[];

    readonly modelName = 'cachedRequest';

    constructor(request: AwareHttpRequest) {
        this.method = request.getMethod();
        this.url = request.getUrl();
        this.body = request.getBody();

        const headerKeys = request.getHeaders().keys();
        const paramKeys = request.getParams().keys();
        const subReqs = request.getSubRequests();

        for (const key of headerKeys) {
            this.headers[key] = request.getHeaders().get(key);
        }

        for (const key of paramKeys) {
            this.parameters[key] = request.getParams().get(key);
        }

        if (subReqs?.size) {
            const tempSubReqs = [];

            subReqs.forEach((req) => {
                tempSubReqs.push(new CachedRequest(req));
            });

            this.subRequests = tempSubReqs;
        }
    }
}
