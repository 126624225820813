import { State, StateContext, Action, Selector, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { orderBy } from 'natural-orderby';

import { StateKey } from '@helpers';
import { Contact } from '../models';
import { DeleteContact, SaveContact } from './contact.actions';

export interface ContactStateModel {
    contacts: Contact[];
}

const defaults = {
    contacts: null,
};

@State<ContactStateModel>({
    name: StateKey.Contact,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class ContactState implements NgxsOnInit {
    @Selector()
    static contacts({ contacts }: ContactStateModel): Contact[] {
        return contacts;
    }

    constructor() {}

    ngxsOnInit({ getState, patchState }: StateContext<ContactStateModel>): void {
        const { contacts } = getState();

        if (!contacts) {
            patchState({
                contacts: [
                    new Contact({ position: 1 }),
                    new Contact({ position: 2 }),
                    new Contact({ position: 3 }),
                ],
            });
        }
    }

    @Action(SaveContact)
    saveContact(
        { getState, patchState }: StateContext<ContactStateModel>,
        { contact }: SaveContact
    ) {
        const { contacts } = getState();
        const newContacts = [...contacts.filter((c) => c.uuid !== contact.uuid), contact];

        patchState({
            contacts: orderBy(newContacts, 'position', 'asc'),
        });
    }

    @Action(DeleteContact)
    deleteCOntact(
        { getState, patchState }: StateContext<ContactStateModel>,
        { contact }: DeleteContact
    ) {
        const { contacts } = getState();
        const { position, uuid } = contact;

        contact = new Contact({
            position,
            uuid,
        });

        const newContacts = [...contacts.filter((c) => c.uuid !== contact.uuid), contact];

        patchState({
            contacts: orderBy(newContacts, 'position', 'asc'),
        });
    }
}
