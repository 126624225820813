import { State, StateContext, Action, Selector, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AwareHttpService } from '@appbolaget/aware-http';

import * as moment from 'moment';

import { orderBy } from 'natural-orderby';

import { StateKey } from '@helpers';
import { Reminder } from 'src/app/models';
import { SaveReminder, DeleteReminder } from './reminder.actions';
import {
    LocalNotifications,
    LocalNotificationSchedule,
    Schedule,
} from '@capacitor/local-notifications';

export interface ReminderStateModel {
    reminders: Reminder[];
}

const defaults = {
    reminders: null,
};

@State<ReminderStateModel>({
    name: StateKey.Reminder,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class ReminderState implements NgxsOnInit {
    @Selector()
    static reminders({ reminders }: ReminderStateModel): Reminder[] {
        return reminders;
    }

    constructor(private api: AwareHttpService) {}

    ngxsOnInit({ dispatch }: StateContext<ReminderStateModel>) {}

    @Action(DeleteReminder)
    async deleteReminder(
        { patchState, getState, dispatch }: StateContext<ReminderStateModel>,
        { reminder }: SaveReminder,
    ) {
        let { reminders } = getState();
        const { id } = reminder;

        LocalNotifications.cancel({
            notifications: [{ id: id }],
        });

        reminders = reminders.filter((r) => r.id !== reminder.id);

        patchState({ reminders });
    }

    @Action(SaveReminder)
    async saveReminder(
        { patchState, getState, dispatch }: StateContext<ReminderStateModel>,
        { reminder, shouldSchedule }: SaveReminder,
    ) {
        let { reminders } = getState();
        const { title, body, repeat, time, date, day, dayInMonth, every, id, month } = reminder;
        const at = new Date(`${date} ${time}`);

        if (typeof id !== 'number') {
            reminder.id = this.getHighestActiveReminderId(reminders) + 1;
        }

        let schedule: Schedule = {
            on: {},
        };

        if (reminder.repeat) {
            if (every === 'week') {
                schedule.on.day = Number(day);
            }

            if (every === 'month' || every === 'year') {
                schedule.on.day = Number(moment(dayInMonth).format('DD'));
            }

            if (every === 'year') {
                schedule.on.month = Number(month);
            }

            schedule.every = every as any;
            schedule.on.hour = Number(moment(time).format('HH'));
            schedule.on.minute = Number(moment(time).format('mm'));
        } else {
            schedule = {
                repeats: repeat,
                at,
            };
        }

        if (shouldSchedule) {
            LocalNotifications.schedule({
                notifications: [
                    {
                        title,
                        body,
                        schedule,
                        id: reminder.id,
                    },
                ],
            }).catch((err) => console.error(err));
        }

        let newReminders = [];

        if (reminders) {
            reminders = reminders.filter((r) => r.id !== reminder.id);
            newReminders = [...reminders];
        }

        newReminders = [reminder, ...newReminders];

        newReminders = orderBy(newReminders, 'id', 'asc');

        patchState({ reminders: newReminders });
    }

    private getHighestActiveReminderId(reminders: Reminder[]): number {
        let id = 0;

        if (reminders) {
            for (const reminder of reminders) {
                if (reminder.id > id) {
                    id = reminder.id;
                }
            }
        }

        return id;
    }
}
