import { Component, OnInit } from '@angular/core';
import { Contact, Contacts } from '@capacitor-community/contacts';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { orderBy } from 'natural-orderby';

@Component({
    selector: 'app-contacts',
    templateUrl: 'contacts.component.html',
    styleUrls: ['contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
    contacts = [];
    allContacts = [];

    constructor(
        private store: Store,
        private modalCtrl: ModalController,
        private actionSheetCtrl: ActionSheetController,
    ) {}

    ngOnInit() {
        this.getContacts();
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    search({ detail: { value } }) {
        if (value) {
            const found = this.allContacts.filter((contact) =>
                contact.name.toLowerCase().includes(value.toLowerCase()),
            );

            this.contacts = found;
        } else {
            this.contacts = this.allContacts;
        }
    }

    async selectContact(contact) {
        let selectedPhoneNumber = contact.phoneNumbers[0];

        if (contact.phoneNumbers.length > 1) {
            selectedPhoneNumber = await this.selectPhoneNumber(contact.phoneNumbers);
        }

        this.modalCtrl.dismiss({
            contact: {
                name: contact.name,
                phoneNumber: selectedPhoneNumber,
            },
        });
    }

    private async getContacts() {
        Contacts.getContacts().then((result) => {
            this.allContacts = result.contacts.map((contact: Contact) => {
                return {
                    phoneNumbers: contact.phoneNumbers,
                    name: contact.displayName,
                };
            });

            this.allContacts = orderBy(this.allContacts, 'name', 'asc');

            this.contacts = this.allContacts;
        });
    }

    private async selectPhoneNumber(phoneNumbers: any[]): Promise<string | null> {
        return new Promise(async (resolve) => {
            phoneNumbers = phoneNumbers.map((phoneNumber: string) => {
                return {
                    text: phoneNumber,
                    icon: 'call',
                    handler: () => resolve(phoneNumber),
                };
            });

            (
                await this.actionSheetCtrl.create({
                    header: 'Välj telefonnummer',
                    buttons: [
                        ...phoneNumbers,
                        {
                            icon: 'close',
                            text: 'Avbryt',
                        },
                    ],
                })
            ).present();
        });
    }
}
