import { Reminder } from 'src/app/models';

export class SaveReminder {
    static type = '[Reminder] Save reminder';

    constructor(public reminder: Reminder, public shouldSchedule = true) {}
}

export class DeleteReminder {
    static type = '[Reminder] Delete reminder';

    constructor(public reminder: Reminder) {}
}
