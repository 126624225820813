import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
    selector: 'app-feedback',
    templateUrl: 'feedback.component.html',
    styleUrls: ['feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
    constructor(private modalCtrl: ModalController, private alertController: AlertController) {}

    ngOnInit() {}

    // presentAlert() {
    //     this.alertController.create({
    //         header: 'Vill du tycka till om Min Livlina?',
    //         cssClass: 'custom-alert',
    //         buttons: [
    //             {
    //                 text: 'Nej, inte nu',
    //                 cssClass: 'alert-button-cancel',
    //             },
    //             {
    //                 text: 'Ja',
    //                 cssClass: 'alert-button-confirm',
    //             },
    //         ],
    //         backdropDismiss: false,
    //     });
    // }

    // close(): void {
    //     this.modalCtrl.dismiss();
    // }
}
