import { Injectable } from '@angular/core';
import { AwareSecurityPermissionProvider } from '@appbolaget/aware-security';
// import { Helpers } from '@helpers';
import { filter, map, tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { AuthState } from '@state';
import decode from 'jwt-decode';
import { environment } from '@env';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PermissionProvider implements AwareSecurityPermissionProvider {
    constructor(private store: Store) {}

    getPermissions() {
        return this.store.select(AuthState.token).pipe(
            map((token) => {
                const decoded: any = decode(token);
                const { permissions, roles } = decoded?.payload;

                return { permissions, roles };
            }),
        );

        // return this.auth.token$.pipe(
        //     filter((token) => token.permissions && token.roles),
        //     map((token) => {
        //         const { permissions, roles } = token;
        //         return { permissions, roles };
        //     })
        // );
    }

    getUnitId() {
        return of([environment.api.unit]);
    }
}
