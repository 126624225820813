import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '@env';

@Pipe({ name: 'customIcon' })
export class IconPipe implements PipeTransform {
    transform(value: string): string {
        return `${environment.api.url}/@media/${environment.api.unit}:${value}`;
    }
}
