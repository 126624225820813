export enum StateKey {
    Auth = 'auth',
    App = 'app',
    Diary = 'diary',
    Contact = 'contact',
    SecurityPlan = 'securityplan',
    Reminder = 'reminder',
    Network = 'network',
}

export enum FeedbackType {
    Emotion = 'emotion',
}
