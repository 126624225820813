import { v4 as uuid } from 'uuid';

export interface ContactInterface {
    uuid?: string;
    name?: string;
    phoneNumber?: string;
    image?: string;
    position: number;
}

export class Contact {
    uuid: string;
    name: string;
    phoneNumber: string;
    image: string;
    isReal: boolean;
    position: number;

    constructor(data?: ContactInterface) {
        this.uuid = data?.uuid || uuid();
        this.name = data?.name || null;
        this.phoneNumber = data?.phoneNumber || null;
        this.image = data?.image || null;
        this.position = data.position;

        /**
         * Check if this is a "real" contact, aka a contact
         * where the user has given it some data.
         */
        if (this.name && this.phoneNumber) {
            this.isReal = true;
        } else {
            this.isReal = false;
        }
    }

    call(event?) {
        if (event) {
            event.stopPropagation();
        }

        window.open(`tel:${this.phoneNumber}`, '_blank');
    }
}
