import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ToastService {
    constructor(private toastCtrl: ToastController) {}

    async info(options: ToastOptions | string) {
        if (typeof options === 'string') {
            options = { message: options };
        }

        const toastOptions = {
            duration: 3000,
            position: 'top',
            buttons: [{ icon: 'close' }],
            ...options,
        } as any;

        (await this.toastCtrl.create(toastOptions)).present();
    }

    async success(options: ToastOptions | string) {
        if (typeof options === 'string') {
            options = { message: options };
        }

        const toastOptions = {
            duration: 3000,
            position: 'top',
            color: 'success',
            buttons: [{ icon: 'close' }],
            ...options,
        } as any;

        (await this.toastCtrl.create(toastOptions)).present();
    }

    async error(options: ToastOptions | string) {
        if (typeof options === 'string') {
            options = { message: options };
        }

        const toastOptions = {
            duration: 3000,
            position: 'top',
            color: 'danger',
            buttons: [{ icon: 'close' }],
            ...options,
        } as any;

        (await this.toastCtrl.create(toastOptions)).present();
    }
}

export interface ToastOptions {
    header?: string;
    message?: string;
    duration?: number;
    position?: 'top' | 'bottom' | 'middle';
    buttons?: any[];
}
