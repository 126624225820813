import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { catchError, takeWhile, tap } from 'rxjs/operators';

import { AuthState, Login, LoginSuccessful, LoginUnsuccessful } from '@state';
import { AwareHttpService } from '@appbolaget/aware-http';
import { throwError } from 'rxjs';
import { RegisterComponent } from '@components/register/register.component';
import { ToastService } from '@services';

@Component({
    selector: 'app-auth',
    templateUrl: 'auth.component.html',
    styleUrls: ['auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    rememberEmailControl = new FormControl(false);

    loader: any;

    ALIVE = true;

    constructor(
        private alertCtrl: AlertController,
        private store: Store,
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private actions$: Actions,
        private loadingCtrl: LoadingController,
        private toastService: ToastService,
        private api: AwareHttpService,
        private navCtrl: NavController,
        private changeRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.listen();
        this.buildForms();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    closeAndGoHome() {
        this.modalCtrl.dismiss();
        this.navCtrl.navigateRoot('tabs/home');
    }

    async login(): Promise<void> {
        const { email, password } = this.loginForm.value;

        this.loader = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: 'Loggar in...',
        });

        this.loader.present();

        this.store.dispatch(
            new Login(
                { username: email, password },
                { rememberEmail: this.rememberEmailControl.value },
            ),
        );
    }

    async openRegistration() {
        (
            await this.modalCtrl.create({
                component: RegisterComponent,
            })
        ).present();
    }

    async showForgotPasswordPrompt() {
        (
            await this.alertCtrl.create({
                header: 'Återställ lösenord',
                message:
                    'Fyll i din e-postadress så skickar vi ett mejl med information för att återställa ditt lösenord.',
                inputs: [
                    {
                        placeholder: 'E-postadress...',
                        name: 'email',
                        type: 'email',
                    },
                ],
                buttons: [
                    'Avbryt',
                    {
                        text: 'Skicka',
                        handler: ({ email }) => this.resetPassword(email),
                    },
                ],
            })
        ).present();
    }

    private async resetPassword(email: string) {
        const loader = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: 'Skickar mejl...',
        });

        loader.present();

        this.api
            .post('authenticate/password/restore', { email })
            .header('source', 'universal.appbolaget.se')
            .execute()
            .pipe(
                catchError(async (err) => {
                    loader.dismiss();

                    this.toastService.error({
                        header: 'Ojdå!',
                        message: 'Något gick fel, vänligen försök igen.',
                    });

                    return throwError(err);
                }),
                tap(async (res) => {
                    loader.dismiss();

                    this.toastService.success(
                        'Vi har skickat ett mejl med instruktioner till dig.',
                    );
                }),
            )
            .subscribe();
    }

    private buildForms(): void {
        const lastSuccessfulEmail = this.store.selectSnapshot(AuthState.lastSuccessfulEmail);

        if (lastSuccessfulEmail) {
            this.rememberEmailControl.patchValue(true);
        }

        this.loginForm = this.formBuilder.group({
            email: [
                lastSuccessfulEmail || '',
                Validators.compose([Validators.required, Validators.email]),
            ],
            password: ['', Validators.required],
        });

        this.loginForm.valueChanges
            .pipe(
                takeWhile(() => this.ALIVE),
                tap(() => {
                    setTimeout(() => {
                        this.loginForm.updateValueAndValidity();
                    }, 100);
                }),
            )
            .subscribe();
    }

    private listen(): void {
        this.actions$
            .pipe(
                ofActionDispatched(LoginSuccessful),
                takeWhile(() => this.ALIVE),
                tap(async () => {
                    this.closeAndGoHome();

                    this.loader.dismiss();

                    this.toastService.success('Du har loggats in');
                }),
            )
            .subscribe();

        this.actions$
            .pipe(
                ofActionDispatched(LoginUnsuccessful),
                takeWhile(() => this.ALIVE),
                tap(async () => {
                    this.loader.dismiss();

                    this.toastService.error({
                        header: 'Ojdå!',
                        message: 'Något gick fel, vänligen försök igen.',
                    });
                }),
            )
            .subscribe();
    }
}
