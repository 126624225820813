import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { catchError, tap } from 'rxjs/operators';

import { AwareHttpService } from '@appbolaget/aware-http';
import { Observable, throwError } from 'rxjs';
import { Post, Unit } from '@appbolaget/aware-model';
import { environment } from '@env';
import { ToastService } from '@services';
import { Select, Store } from '@ngxs/store';
import { AppState } from '@state/app.state';
import { GetOrganisation, GetRegions } from '@state/app.actions';
import { SwiperContainer } from 'swiper/element';

@Component({
    selector: 'app-register',
    templateUrl: 'register.component.html',
    styleUrls: ['register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
    @ViewChild('slides') slides: SwiperContainer;
    @Select(AppState.regions) regions$: Observable<Unit[]>;
    @Select(AppState.organisation) organisation$: Observable<Unit>;

    form: FormGroup;

    loader: any;

    slideOptions = {
        allowTouchMove: false,
        initialSlide: 0,
        autoHeight: true,
    };

    currentSlide = 0;
    terms: Post;

    ALIVE = true;

    constructor(
        private modalCtrl: ModalController,
        private formBuilder: FormBuilder,
        private loadingCtrl: LoadingController,
        private toastService: ToastService,
        private api: AwareHttpService,
        private store: Store,
    ) {}

    ngOnInit() {
        this.store.dispatch([new GetRegions(), new GetOrganisation()]);
        this.buildForms();
        this.getTerms();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    async goToTerms() {
        // await this.slides.slideNext();
        this.currentSlide = 1;
    }

    async backToForm() {
        // await this.slides.slidePrev();
        this.currentSlide = 0;
    }

    async finalizeRegistration() {
        const { email, password, givenName, familyName, region, userType } = this.form.value;
        const loader = await this.loadingCtrl.create({
            spinner: 'crescent',
            message: 'Skapar konto...',
        });

        loader.present();

        this.api
            .post('clients', {
                email,
                // password,
                given_name: givenName,
                family_name: familyName,
                attach: true,
                template: 'password-url-welcome',
                attributes: [
                    {
                        key: 'userType',
                        simple: userType.uuid,
                    },
                ],
            })
            .header('Source', 'universal.appbolaget.se')
            .unit(region.uuid)
            .execute()
            .pipe(
                catchError(async (err) => {
                    loader.dismiss();

                    this.toastService.error({
                        duration: 5000,
                        header: 'Ojdå, Något gick fel.',
                        message:
                            'Anledningen är troligen att den e-postadress du valt att registrera använts tidigare. Testa att klicka på "Glömt lösenord" i inloggningsläget.',
                    });

                    throw throwError(err);
                }),
                tap(async (res) => {
                    loader.dismiss();

                    this.toastService.success('Vi har skickat ett bekräftelsemejl till dig.');

                    this.close();
                }),
            )
            .subscribe();
    }

    private getTerms() {
        this.api
            .get(`posts/${environment.api.unit}:terms`)
            .toModel(Post)
            .execute()
            .pipe(tap((post) => (this.terms = post)))
            .subscribe();
    }

    private buildForms(): void {
        this.form = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, Validators.email])],
            // password: ['', Validators.required],
            givenName: ['', Validators.required],
            familyName: ['', Validators.required],
            region: [null, Validators.required],
            userType: [null, Validators.required],
        });
    }
}
