import { State, StateContext, Action, Selector, NgxsOnInit } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AwareHttpService } from '@appbolaget/aware-http';

import { retryWithDelay, StateKey } from '@helpers';
import {
    GetMoreNumbersPageMenu,
    GetMorePageMenu,
    GetTabsMenu,
    SetSplashAnimationDone,
    SetAppReady,
    SetSplashLoaded,
    SetWalkthroughCompleted,
    SetCurrentPost,
    GetRegions,
    GetOrganisation,
    IncrementLaunchCount,
    ResetLaunchCount,
    NullLaunchCount,
    SetLandingPageCompleted,
} from './app.actions';
import { environment } from '@env';
import { map, tap } from 'rxjs/operators';
import { orderBy } from 'natural-orderby';
import { Navigation } from '../models/navigation';
import { Post } from '../models';
import { Unit } from '@appbolaget/aware-model';
import { pipe } from 'rxjs';
import { Capacitor } from '@capacitor/core';

export interface AppStateModel {
    stateInitialized: boolean;
    isNative: boolean;
    morePageMenu: Navigation;
    moreNumbersPageMenu: Navigation;
    tabsMenu: Navigation;
    splashAnimationDone: boolean;
    appReady: boolean;
    splashLoaded: boolean;
    walkthroughCompleted: boolean;
    landingPageCompleted: boolean;
    currentPost: Post;
    regions: Unit[];
    organisation: Unit;
    appLaunchCount: number;
}

const defaults = {
    stateInitialized: false,
    isNative: false,
    morePageMenu: null,
    moreNumbersPageMenu: null,
    tabsMenu: null,
    splashAnimationDone: false,
    appReady: false,
    splashLoaded: false,
    walkthroughCompleted: false,
    landingPageCompleted: false,
    currentPost: null,
    regions: null,
    organisation: null,
    appLaunchCount: 0,
};

@State<AppStateModel>({
    name: StateKey.App,
    defaults,
})
@Injectable({ providedIn: 'root' })
export class AppState implements NgxsOnInit {
    @Selector()
    static stateInitialized({ stateInitialized }: AppStateModel): boolean {
        return stateInitialized;
    }

    @Selector()
    static isNative({ isNative }: AppStateModel): boolean {
        return isNative;
    }

    @Selector()
    static morePageMenu({ morePageMenu }: AppStateModel): Navigation {
        return morePageMenu;
    }

    @Selector()
    static splashAnimationDone({ splashAnimationDone }: AppStateModel): boolean {
        return splashAnimationDone;
    }

    @Selector()
    static appReady({ appReady }: AppStateModel): boolean {
        return appReady;
    }

    @Selector()
    static regions({ regions }: AppStateModel): Unit[] {
        return regions;
    }

    @Selector()
    static appLaunchCount({ appLaunchCount }: AppStateModel): number {
        return appLaunchCount;
    }

    @Selector()
    static organisation({ organisation }: AppStateModel): Unit {
        return organisation;
    }

    @Selector()
    static splashLoaded({ splashLoaded }: AppStateModel): boolean {
        return splashLoaded;
    }

    @Selector()
    static moreNumbersPageMenu({ moreNumbersPageMenu }: AppStateModel): Navigation {
        return moreNumbersPageMenu;
    }

    @Selector()
    static tabsMenu({ tabsMenu }: AppStateModel): Navigation {
        return tabsMenu;
    }

    @Selector()
    static walkthroughCompleted({ walkthroughCompleted }: AppStateModel): boolean {
        return walkthroughCompleted;
    }

    @Selector()
    static landingPageCompleted({ landingPageCompleted }: AppStateModel): boolean {
        return landingPageCompleted;
    }

    @Selector()
    static currentPost({ currentPost }: AppStateModel): Post {
        return currentPost;
    }

    constructor(private api: AwareHttpService) {}

    ngxsOnInit({ dispatch, patchState }: StateContext<AppStateModel>): void {
        patchState({ stateInitialized: true, isNative: Capacitor.isNativePlatform() });
    }

    @Action(GetOrganisation)
    getOrganisation({ patchState }: StateContext<AppStateModel>) {
        return this.api
            .get(`units/${environment.api.unit}`)
            .expand(['attributes'])
            .toModel(Unit)
            .execute()
            .pipe(
                tap((organisation) => {
                    try {
                        organisation.config = JSON.parse(organisation.config);
                    } catch (error) {}

                    patchState({ organisation });
                }),
            )
            .subscribe();
    }

    @Action(SetCurrentPost)
    setCurrentPost({ patchState }: StateContext<AppStateModel>, { post }: SetCurrentPost) {
        patchState({
            currentPost: post,
        });
    }

    @Action(GetMorePageMenu)
    getMorePageMenu({ patchState }: StateContext<AppStateModel>) {
        return this.api
            .get(`categories/${environment.api.unit}:more`)
            .expand(['children.meta', 'children.posts.meta', 'children.attributes', 'children.posts.media'])
            .toModel(Navigation)
            .execute()
            .pipe(
                tap(async (morePageMenu) => {
                    morePageMenu.children = orderBy(morePageMenu.children, 'position', 'asc');

                    morePageMenu = await this.parseNavigationPosts(morePageMenu);

                    patchState({ morePageMenu });
                }),
            )
            .pipe(retryWithDelay(5000, Infinity));
    }

    @Action(GetMoreNumbersPageMenu)
    getMoreNumbersPageMenu({ patchState }: StateContext<AppStateModel>) {
        return this.api
            .get(`categories/${environment.api.unit}:numbers`)
            .expand(['children.meta', 'children.posts.meta', , 'children.posts.media'])
            .toModel(Navigation)
            .execute()
            .pipe(
                tap(async (moreNumbersPageMenu) => {
                    moreNumbersPageMenu.children = orderBy(moreNumbersPageMenu.children, 'position', 'asc');

                    moreNumbersPageMenu = await this.parseNavigationPosts(moreNumbersPageMenu);

                    patchState({ moreNumbersPageMenu });
                }),
            )
            .pipe(retryWithDelay(5000, Infinity));
    }

    @Action(SetSplashAnimationDone)
    setsplashAnimationDone({ patchState }: StateContext<AppStateModel>) {
        patchState({ splashAnimationDone: true });
    }

    @Action(GetRegions)
    getRegions({ patchState }: StateContext<AppStateModel>) {
        return this.api
            .get('units')
            .with('attributes')
            .toCollection(Unit)
            .execute()
            .pipe(
                tap(({ data }) => {
                    patchState({ regions: data });
                }),
            );
    }

    @Action(SetSplashLoaded)
    setSplashLoaded({ patchState }: StateContext<AppStateModel>) {
        patchState({ splashLoaded: true });
    }

    @Action(SetAppReady)
    setAppReady({ patchState }: StateContext<AppStateModel>) {
        patchState({ appReady: true });
    }

    @Action(GetTabsMenu)
    getTabsMenu({ patchState }: StateContext<AppStateModel>) {
        return this.api
            .get(`categories/${environment.api.unit}:tabs`)
            .expand(['children.meta', 'children.posts.meta', 'children.attributes', 'children.posts.media'])
            .toModel(Navigation)
            .execute()
            .pipe(
                tap(async (tabsMenu) => {
                    tabsMenu.children = orderBy(tabsMenu.children, 'position', 'asc');

                    tabsMenu = await this.parseNavigationPosts(tabsMenu);

                    patchState({ tabsMenu });
                }),
            )
            .pipe(retryWithDelay(5000, Infinity));
    }

    @Action(SetWalkthroughCompleted)
    setWalkthroughCompleted({ patchState }: StateContext<AppStateModel>, { value }: SetWalkthroughCompleted) {
        patchState({
            walkthroughCompleted: value,
        });
    }

    @Action(SetLandingPageCompleted)
    setLandingPageCompleted({ patchState }: StateContext<AppStateModel>, { value }: SetLandingPageCompleted) {
        patchState({
            landingPageCompleted: value,
        });
    }

    private async parseNavigationPosts(navigation: Navigation): Promise<Navigation> {
        if (navigation.posts) {
            for (const post of navigation.posts) {
                if (post.media?.length) {
                    for (const image of post.media) {
                        const base64 = await this.downloadImage(image.uuid);

                        post.base64Media.push(base64);
                    }
                }
            }
        }

        if (navigation.post) {
            if (navigation.post.media?.length) {
                for (const image of navigation.post.media) {
                    const base64 = await this.downloadImage(image.uuid);

                    navigation.post.base64Media.push(base64);
                }
            }
        }

        if (navigation.children?.length) {
            for (const child of navigation.children) {
                for (const post of child.posts) {
                    if (post.media?.length) {
                        for (const image of post.media) {
                            const base64 = await this.downloadImage(image.uuid);

                            post.base64Media.push(base64);
                        }
                    }
                }

                if (child.post) {
                    if (child.post.media?.length) {
                        for (const image of child.post.media) {
                            const base64 = await this.downloadImage(image.uuid);

                            child.post.base64Media.push(base64);
                        }
                    }
                }
            }
        }

        return navigation;
    }

    private async downloadImage(uuid: string): Promise<string> {
        const data = await fetch(`${environment.api.url}/@media/${uuid}?w=1000`);
        const blob = await data.blob();

        const base64 = await new Promise<string>(async (resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);

            reader.onloadend = () => {
                const base64data = reader.result;

                resolve(base64data as string);
            };
        });

        return base64;
    }

    @Action(IncrementLaunchCount)
    setLaunchCount({ patchState, getState }: StateContext<AppStateModel>) {
        const { appLaunchCount } = getState();

        patchState({
            appLaunchCount: appLaunchCount + 1,
        });
    }

    @Action(ResetLaunchCount)
    resetLaunchCount({ patchState }: StateContext<AppStateModel>) {
        patchState({
            appLaunchCount: 0,
        });
    }

    @Action(NullLaunchCount)
    nullLaunchCount({ patchState }: StateContext<AppStateModel>) {
        patchState({
            appLaunchCount: 0,
        });
    }
}
