import { AwareHttpRequest } from '@appbolaget/aware-http';

export class SetConnected {
    static type = '[Network] Set connected';

    constructor(public connected: boolean) {}
}

export class SetInitialNetworkStatusTrue {
    static type = '[Network] Set initial network status true';
}

export class SaveRequestToCache {
    static type = '[Network] Save request to cache';

    constructor(public request: AwareHttpRequest) {}
}

export class ExecuteCachedRequests {
    static type = '[Network] Execute cached requests';
}
