import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AwareHttpModule, AwareHttpRequestModule } from '@appbolaget/aware-http';

import { NgxsModule, Store } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@env';
import {
    NgxsAsyncStorageEngine,
    customStateSerializer,
    customStateDeserializer,
    AuthState,
    AuthStateModel,
    RefreshToken,
    Logout,
    AppState,
    ResetIsCheckingToken,
    DiaryState,
    ContactState,
    SecurityPlanState,
    ReminderState,
    NetworkState,
    SetConnected,
} from '@state';
import { Plugins } from '@capacitor/core';
import { AwareSecurityModule, AwareSecurityPermissionProvider } from '@appbolaget/aware-security';
import { PermissionProvider } from './services/permission.provider';
import { StateKey } from '@helpers';
import { AwHttpInterceptor } from '@services/http.interceptor';
import { SharedModule } from './shared.module';
import { SplashScreen } from '@capacitor/splash-screen';
import { NgxsAsyncStoragePluginModule } from '@modules/ngxs-async-storage/async-storage.module';
import { MomentModule } from 'ngx-moment';

@NgModule({
    declarations: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        MomentModule,
        BrowserModule,
        HttpClientModule,
        HammerModule,
        SharedModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'md',
            backButtonText: 'Tillbaka',
            swipeBackEnabled: true,
        }),
        AwareHttpModule.forRoot({
            basePath: environment.api.url,
            defaultHeaders: {
                'content-type': 'application/json',
                source: environment.api.source,
                language: 'sv',
                location: 'se',
                timezone: 'Europe/Stockholm',
                module: AwareHttpRequestModule.Cms,
                unit: environment.api.unit,
            },
        }),
        AwareSecurityModule.forRoot(),
        NgxsModule.forRoot([AppState, AuthState, ContactState, DiaryState, ReminderState, SecurityPlanState, NetworkState], {
            developmentMode: !environment.production,
        }),
        NgxsLoggerPluginModule.forRoot({
            disabled: environment.production,
        }),
        NgxsAsyncStoragePluginModule.forRoot(NgxsAsyncStorageEngine, {
            key: [
                StateKey.Auth,
                StateKey.Contact,
                StateKey.Reminder,
                StateKey.SecurityPlan,
                `${StateKey.App}.morePageMenu`,
                `${StateKey.App}.moreNumbersPageMenu`,
                `${StateKey.App}.tabsMenu`,
                `${StateKey.App}.walkthroughCompleted`,
                `${StateKey.App}.organisation`,
                `${StateKey.Network}.cachedRequests`,
                `${StateKey.App}.appLaunchCount`,
                `${StateKey.App}.landingPageCompleted`,
            ],
            serialize: customStateSerializer,
            deserialize: customStateDeserializer,
        }),
        AppRoutingModule,
    ],
    providers: [
        { provide: AwareSecurityPermissionProvider, useClass: PermissionProvider },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            deps: [Store],
            multi: true,
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: AwHttpInterceptor,
        //     multi: true,
        // },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function appInit(store: Store): any {
    return async () => {
        const initted$ = new Subject<void>();
        const { connected } = await Plugins.Network.getStatus();

        return await store
            .select(AppState.stateInitialized)
            .pipe(
                takeUntil(initted$),
                filter((stateInitialized) => stateInitialized),
                switchMap((_) => store.selectOnce(AuthState)),
                switchMap((authState: AuthStateModel) => {
                    store.dispatch(new ResetIsCheckingToken());

                    const splashHidden$ = new Subject<void>();
                    const splashObservable = store.select(AppState.splashLoaded).pipe(
                        takeUntil(splashHidden$),
                        tap((loaded) => {
                            if (loaded) {
                                SplashScreen.hide();

                                splashHidden$.next();
                            }
                        }),
                    );

                    if (authState.client && authState.token && authState.refreshToken && connected) {
                        return store.dispatch(new RefreshToken()).pipe(
                            timeout(3000),
                            tap(() => {
                                initted$.next();

                                splashObservable.subscribe();
                            }),
                            catchError((e) => {
                                store.dispatch(new Logout());

                                splashObservable.subscribe();

                                return of(null);
                            }),
                        );
                    } else {
                        initted$.next();
                    }

                    splashObservable.subscribe();

                    return of(true);
                }),
            )
            .toPromise();
    };
}
