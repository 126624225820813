// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-container {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #64b97d;
}
.splash-container .splash {
  width: 100%;
  height: 100%;
}

::ng-deep .alert-button-group .alert-button-cancel {
  color: darkgray;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLFlBQUE7RUFDQSxXQUFBO0VBQ0Esa0JBQUE7RUFDQSx5QkFBQTtBQUNKO0FBQ0k7RUFDSSxXQUFBO0VBQ0EsWUFBQTtBQUNSOztBQUtRO0VBQ0ksZUFBQTtBQUZaIiwiZmlsZSI6ImFwcC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5zcGxhc2gtY29udGFpbmVyIHtcbiAgICBoZWlnaHQ6IDEwMCU7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICAgIGJhY2tncm91bmQtY29sb3I6ICM2NGI5N2Q7XG5cbiAgICAuc3BsYXNoIHtcbiAgICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICAgIGhlaWdodDogMTAwJTtcbiAgICB9XG59XG5cbjo6bmctZGVlcCB7XG4gICAgLmFsZXJ0LWJ1dHRvbi1ncm91cCB7XG4gICAgICAgIC5hbGVydC1idXR0b24tY2FuY2VsIHtcbiAgICAgICAgICAgIGNvbG9yOiBkYXJrZ3JheTtcbiAgICAgICAgfVxuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,kBAAA;EACA,yBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;;AAKQ;EACI,eAAA;AAFZ;AACA,gwBAAgwB","sourcesContent":[".splash-container {\n    height: 100%;\n    width: 100%;\n    position: relative;\n    background-color: #64b97d;\n\n    .splash {\n        width: 100%;\n        height: 100%;\n    }\n}\n\n::ng-deep {\n    .alert-button-group {\n        .alert-button-cancel {\n            color: darkgray;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
