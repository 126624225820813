import { Feedback } from '../models';

export class SaveFeedback {
    static type = '[Diary] Create feedback';

    constructor(public feedback: Feedback) {}
}

export class GetDiary {
    static type = '[Diary] Get diary';
}

export class SortDiary {
    static type = '[Diary] Sort diary';
}
export class CheckIfLoggedToday {
    static type = '[Diary] Check if the user has logged today';
}
