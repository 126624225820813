export const environment = {
    production: false,
    local: true,
    envName: 'local',
    api: {
        url: 'https://api-universal.appbolaget.se',
        // url: 'https://api-universal-dev.appbolaget.se',
        unit: 'de2e5a47-c2d3-4696-a423-c1feee660eaf',
        unit_id: 297,
        source: 'suicide-zero.appbolaget.se',
    },
    app: {
        id: 'se.appbolaget.suicide-zero',
        version: '1.0.0',
    },
};
