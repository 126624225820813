import { Component, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { AuthState, DiaryState } from '@state';
import { catchError, filter, takeWhile, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { AwareHttpRequestModule, AwareHttpService } from '@appbolaget/aware-http';
import { Client } from '@appbolaget/aware-model';
import { throwError } from 'rxjs';
import { MonthPipe } from 'src/app/pipes';
import { ToastService } from '@services';
import { Chart, ChartItem, registerables } from 'chart.js';
import { HttpClient } from '@angular/common/http';
import { template } from './diary-pdf-template';
import { getRelativePosition } from 'chart.js/helpers';
import { Feedback } from 'src/app/models';

@Component({
    selector: 'app-diary-stats',
    templateUrl: 'diary-stats.component.html',
    styleUrls: ['diary-stats.component.scss'],
})
export class DiaryStatsComponent implements OnInit, OnDestroy {
    ALIVE = true;

    selectedTimePeriod = signal<any>(null);
    feedback = signal<any[]>([]);
    hoveredFeedback = signal<{ year: number; month: number; feedback: Feedback; score: number } | null>(null);

    charts: Chart[] = [];

    constructor(
        private modalCtrl: ModalController,
        private store: Store,
        private alertCtrl: AlertController,
        private toastService: ToastService,
        private loadingCtrl: LoadingController,
        private api: AwareHttpService,
        private http: HttpClient,
        private actionSheetCtrl: ActionSheetController,
        private monthPipe: MonthPipe,
    ) {}

    ngOnInit() {
        Chart.register(...registerables);

        this.store
            .select(DiaryState.dateSortedFeedback)
            .pipe(
                takeWhile(() => this.ALIVE),
                filter((f) => !!f),
                tap((f) => {
                    const feedback = [...f];
                    const flattened = [];

                    for (const year of feedback) {
                        for (const month of year.months) {
                            flattened.push({
                                year: year.year,
                                month,
                            });
                        }
                    }

                    console.log(flattened);

                    this.feedback.set(flattened);

                    setTimeout(() => {
                        this.createChart();
                    }, 300);
                }),
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.ALIVE = false;
    }

    close(): void {
        this.modalCtrl.dismiss();
    }

    timePeriodSelected({ detail: { value } }) {
        value.chartData = [
            {
                name: 'data',
                series: value.month.data
                    .map((d) => {
                        return {
                            value: d.score,
                            name: moment(d.created_at).format('DD'),
                        };
                    })
                    .reverse(),
            },
        ];

        this.selectedTimePeriod.set(value);

        this.createChart();
    }

    async exportAsPdf() {
        (
            await this.alertCtrl.create({
                header: 'Ladda ned mående',
                message: 'Om du väljer att ladda ned ditt mående så kan du skicka det till din eller någon annans e-postadress.',
                buttons: [
                    'Avbryt',
                    {
                        text: 'Ladda ned',
                        handler: () => this.showEmailSelectionActionSheet(null),
                    },
                ],
            })
        ).present();
    }

    // private async finalizeExport() {
    //     const loader = await this.loadingCtrl.create({
    //         message: 'Skapar PDF...',
    //         spinner: 'crescent',
    //     });

    //     loader.present();

    //     setTimeout(() => {
    //         const contentEl = document.getElementById(`chart-container`);

    //         const docWidth = 210;
    //         const docHeight = 297;
    //         const docMargin = 10;

    //         domtoimage
    //             .toPng(contentEl)
    //             .then(async (png) => {
    //                 const doc = new jsPDF({
    //                     unit: 'mm',
    //                 });

    //                 let x = docMargin;
    //                 let y = docMargin;
    //                 let highestHeight = 0;

    //                 const imageDimensions = await this.getImageHeightAndWidthFromBase64(png);
    //                 const width = docWidth - docMargin * 2 - 20;
    //                 const height = width * (imageDimensions.h / imageDimensions.w) - 20;

    //                 doc.setFontSize(20);
    //                 doc.text(
    //                     `${this.monthPipe.transform(this.selectedTimePeriod().month.month)}, ${
    //                         this.selectedTimePeriod().year
    //                     }`,
    //                     10,
    //                     10,
    //                 );

    //                 doc.addImage(png, 10, 10, width, height);

    //                 // doc.save();

    //                 setTimeout(() => {
    //                     const blob = doc.output('blob');
    //                     const reader = new FileReader();

    //                     reader.readAsDataURL(blob);
    //                     reader.onloadend = () => {
    //                         const base = reader.result as string;

    //                         loader.dismiss();

    //                         this.showEmailSelectionActionSheet(base);
    //                     };
    //                 }, 300);
    //             })
    //             .catch((err) => console.error(err));
    //     }, 1000);
    // }

    // getImageHeightAndWidthFromBase64(base): Promise<{ h: number; w: number }> {
    //     return new Promise(function (resolve, reject) {
    //         var i = new Image();

    //         i.onload = function () {
    //             resolve({ w: i.width, h: i.height });
    //         };

    //         i.src = base;
    //     });
    // }

    async showEmailSelectionActionSheet(pdfBase: string) {
        (
            await this.actionSheetCtrl.create({
                header: 'Mejla summering',
                backdropDismiss: false,
                buttons: [
                    {
                        text: 'Till mig',
                        icon: 'person-outline',
                        handler: () => {
                            this.sendPDF();
                        },
                    },
                    {
                        text: 'Till någon annan',
                        icon: 'people-outline',
                        handler: async () => {
                            (
                                await this.alertCtrl.create({
                                    header: 'Fyll i e-postadress att skicka till',
                                    inputs: [
                                        {
                                            placeholder: 'E-post...',
                                            type: 'email',
                                            name: 'email',
                                        },
                                    ],
                                    buttons: [
                                        'Avbryt',
                                        {
                                            text: 'Skicka',
                                            handler: ({ email }) => {
                                                this.sendPDF(email);
                                            },
                                        },
                                    ],
                                })
                            ).present();
                        },
                    },
                    {
                        text: 'Avbryt',
                        icon: 'close',
                        handler: () => {},
                    },
                ],
            })
        ).present();
    }

    async sendPDF(email?: string) {
        if (!email) {
            const client: Client = this.store.selectSnapshot(AuthState).client;
            email = client.getCommunicationWithType('email').value;
        }

        const loader = await this.loadingCtrl.create({
            message: 'Skickar mejl...',
            spinner: 'crescent',
        });

        loader.present();

        const parsedTemplate = template.replace('{{dataAndLabels}}', JSON.stringify(this.getDataAndLabelsForChart()));

        this.http
            .post(
                'https://attendo-render.appbolaget.se/browser/pdf',
                {
                    content: parsedTemplate,
                },
                {
                    responseType: 'blob',
                },
            )
            .subscribe((res) => {
                const reader = new FileReader();

                reader.readAsDataURL(res);
                reader.onloadend = () => {
                    const base = reader.result as string;

                    this.api
                        .post('email', {
                            template: 'sz:pdf',
                            subject: 'Min Livlina summering av ditt mående',
                            to: email,
                            attachments: [base],
                        })
                        .module(AwareHttpRequestModule.Broadcast)
                        .execute()
                        .pipe(
                            tap(async (res) => {
                                loader.dismiss();

                                this.toastService.success({
                                    header: 'Nedladdning klar!',
                                    message: `Ett mejl med ditt mående har skickats till ${email}`,
                                });
                            }),
                            catchError(async (err) => {
                                loader.dismiss();

                                this.toastService.error({
                                    header: 'Ojdå!',
                                    message: 'Något gick fel, vänligen försök igen.',
                                });

                                return throwError(() => err);
                            }),
                        )
                        .subscribe();
                };
            });
    }

    private getDataAndLabelsForChart() {
        const dataAndLabels = [];

        for (const option of this.feedback()) {
            console.log(option);
            const labels = option.month.data.map((d) => moment(d.created_at).locale('sv').format('ddd DD')).reverse();
            const data = option.month.data.map((d) => d.score).reverse();

            dataAndLabels.push({
                labels,
                data,
                feedbacks: option.month.data,
                year: option.year,
                month: option.month.month,
                monthText: this.monthPipe.transform(option.month.month),
            });
        }

        return dataAndLabels;
    }

    private createChart() {
        if (this.charts.length) {
            for (const chart of this.charts) {
                chart.destroy();
            }
        }

        const dataAndLabels = this.getDataAndLabelsForChart();

        for (const dataAndLabel of dataAndLabels) {
            const { labels, data, year, month } = dataAndLabel;

            const chartContainer = document.getElementById(`chart-container-${year}-${month}`) as ChartItem;

            const chart = new Chart(chartContainer, {
                type: 'bar',
                data: {
                    labels,
                    datasets: [
                        {
                            data: data,
                            label: 'Mående',
                            backgroundColor: '#64b97d',
                            hoverBackgroundColor: '#000',
                        },
                    ],
                },
                options: {
                    onHover: (event, active, chart) => {
                        if (active.length) {
                            const first = active[0];
                            const setIndex = first.datasetIndex;
                            const index = first.index;
                            const data = chart.data.datasets[setIndex].data[index];

                            this.hoveredFeedback.set({
                                year,
                                month,
                                score: data as number,
                                feedback: dataAndLabel.feedbacks[index],
                            });
                        }
                    },
                    responsive: true,
                    scales: {
                        y: {
                            ticks: {
                                stepSize: 1,
                            },
                            max: 5,
                            min: 0,
                            beginAtZero: true,
                        },
                        x: {
                            afterBuildTicks: (tick) => {
                                // Remove all ticks except first and last one
                                tick.ticks = tick.ticks.filter((_, i) => i === 0 || i === tick.ticks.length - 1);
                            },
                        },
                    },
                    plugins: {
                        tooltip: {
                            enabled: false,
                        },
                        legend: {
                            display: false,
                        },
                    },
                },
            });

            this.charts.push(chart);
        }

        // const month = this.selectedTimePeriod().month;
        // const labels = month.data.map((d) => moment(d.created_at).format('DD')).reverse();
        // const data = month.data.map((d) => d.score).reverse();
    }
}
