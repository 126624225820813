import { v4 as uuid } from 'uuid';

export interface ReminderInterface {
    id?: number | string;
    repeat?: boolean;
    date?: string;
    time?: string;
    body?: string;
    title: string;
    every?: string;
    day?: number;
    dayInMonth?: number;
    month?: number;
    paused?: boolean;
}

export class Reminder {
    id: number;
    repeat: boolean;
    date: string;
    time: string;
    body: string;
    title: string;
    every: string;
    day: number;
    dayInMonth: number;
    month: number;
    paused: boolean;

    constructor(data?: ReminderInterface) {
        this.repeat = data?.repeat;
        this.date = data?.date || null;
        this.time = data?.time || null;
        this.body = data?.body || null;
        this.title = data?.title || null;
        this.every = data?.every || null;
        this.day = data?.day || null;
        this.dayInMonth = data?.dayInMonth || null;
        this.month = data?.month || null;
        this.paused = data?.paused || false;

        if (data?.id) {
            if (typeof data.id === 'number') {
                this.id = data.id;
            } else {
                this.id = Number(data.id);
            }
        }
    }
}
